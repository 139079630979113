import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";

export default function RoundRobinStanding() {

    const { getAccessToken } = useAuth();
    const { tournamentId } = useParams();
    const [loading, setLoading] = useState(false);
    const [pointsTable, setPointsTable] = useState([]);

    const fetchRoundRobbinStandingData = async () => {
        try {
            setLoading(true);
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchRoundRobbinStanding}/${tournamentId}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message);
            }
            console.log("Round Robbin Standings Point Table", result?.response?.pointsTable);
            setPointsTable(result?.response?.pointsTable);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchRoundRobbinStandingData();
    }, []);

    const columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            width: "80px",
        },
        {
            name: "Name",
            selector: (row) => <div>{row?.participantName}</div>,
            center: true,
        },
        {
            name: "P",
            selector: (row) => <div>{row?.plays}</div>,
            center: true,
        },
        {
            name: "W",
            selector: (row) => <div>{row?.wins}</div>,
            center: true,
        },
        {
            name: "D",
            selector: (row) => <div>{row?.draws}</div>,
            center: true,
        },
        {
            name: "L",
            selector: (row) => <div>{row?.losses}</div>,
            center: true,
        },
        {
            name: "Points",
            selector: (row) => <div>{row?.points}</div>,
        },
    ];


    const customStyles = {
        headRow: {
            style: {
                fontWeight: 600,
                color: "#4D4D4D",
                fontSize: "13px",
                // background: "#EAECF0"
            },
        },
        head: {
            style: {
                background: "#EAECF0",
            },
        },

        rows: {
            style: {
                background: "#FFF",
                color: "#565656",
                padding: "5px 0px",
                fontSize: "14px",
                fontWeight: 400,
                width: "auto",
            },
        },
        table: {
            style: {
                overflow: "visible",
                minWidth: "1100px",
            },
        },
        tableWrapper: {
            style: {
                overflow: "visible",
            },
        },
        responsiveWrapper: {
            style: {
                overflowX: "auto",
            },
        },
    };


    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-[50vh]">
                <RotatingLines
                    visible={true}
                    height="100"
                    width="100"
                    color="gray"
                    strokeColor="#FB6108"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        );
    }

    return (
        <div className="overflow-x-auto w-[100%]">
            <DataTable
                columns={columns}
                data={pointsTable}
                customStyles={customStyles}
                pagination
                paginationPerPage={10}
                selectableRows
                progressPending={""}
            />
            {/* <div className='flex justify-center'>
            <p className='text-xl font-semibold'>Add/Subtract Points</p>
            <Select>

            </Select>
        </div> */}
        </div>
    );
}
