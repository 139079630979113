import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { toast } from "react-toastify";
import { PulseLoader } from 'react-spinners';
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";

export default function OverViewEditTeamModal({ setEditTeamModal, participantId, selectionType, refetch }) {

  const [teamName, setTeamName] = useState("")
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const { getAccessToken } = useAuth();

  const fetchSpecificTeamDetails = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchExistingTeamDetails}/${participantId}/${selectionType}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message)
      }
      console.log("Fetching Existing Team Details Successfully", result?.response)
      setTeamName(result?.response?.participants?.name)
    } catch (error) {
      console.log("error in fetch team : ", error?.message);
    }
  }

  useEffect(() => {
    fetchSpecificTeamDetails();
  }, [])

  const handleEditTeam = async () => {
    if ((!teamName)) {
      setShowError(true)
      return;
    }
    try {
      const token = await getAccessToken();
      setIsLoading(true)
      const response = await fetch(`${APIurls.editTeamName}/${participantId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ teamName, selectionType })
      })
      const result = await response.json();
      if (!response.ok) {
        console.log("Error in Updating Participant Name", result)
        toast.error("Error in Updating Participant Name")
      }
      console.log("Participant Name Updated Successfully", result)
      setEditTeamModal(false)
      refetch();
      toast.success("Participant Name Updated Successfully")
    }
    catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
      <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[30%] relative">
        <div className="flex justify-between items-center">
          <h1 className="text-[20px] font-bold">Edit Team</h1>
          <div
            onClick={() => setEditTeamModal(false)}
            className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm cursor-pointer"
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.7rem]" />
          </div>
        </div>
        <div className="flex flex-col gap-[0.5rem]">
          <input
            className="font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none"
            type="text"
            placeholder="Team Name"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
          />
          {!teamName && showError && (
            <p className="text-red-600 text-[13px]">This field is required*</p>
          )}
        </div>
        <div className="w-[90px] flex items-center justify-center text-white bg-primary px-4 py-2 cursor-pointer rounded-md gap-1"
          onClick={handleEditTeam}
        >
          {
            isLoading ? <PulseLoader color="white" size={8} /> :
              <div className="flex justify-center items-center gap-1">
                <button type="button" className="text-[0.8rem] sm:text-[1rem] font-semibold">
                  Save
                </button>
                <PiGreaterThan />
              </div>
          }
        </div>
      </div>
    </div>
  );
}
