import React, { useState } from 'react'
import { PiGreaterThan } from "react-icons/pi";
import { Icon } from "@iconify/react";
import { AiOutlinePlus } from "react-icons/ai";
import DataTable from 'react-data-table-component';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import AddParticipantModal from './AddParticipantModal';
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { useQuery } from 'react-query';

export default function ViewTeamParticipants() {

    const [activeParticipants, setActivePartcipants] = useState("participants")
    const [participantModal, setParticipantModal] = useState(false)

    const { getAccessToken } = useAuth();
    const navigate = useNavigate();
    const { tournamentId } = useParams();
    const { teamId } = useParams();

    const fetchSpecificTeamDetails = async () => {
        const token = await getAccessToken();
        const response = await fetch(`${APIurls.fetchPlayersFromSpecificTeam}/${teamId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const result = await response.json()
        if (!response.ok) {
            throw new Error(result.message)
        }
        return result?.response?.team
    }
    const { data: teamDetails, refetch, isLoading } = useQuery(["TeamDetails",teamId], fetchSpecificTeamDetails)
    console.log("Specific Team Details", teamDetails)

    // const handleDeleteParticipant = async (id) =>{
    //     console.log("Participant id=>",id)
    //     const token = await getAccessToken();
    //     try{
    //         const response = await fetch(`${APIurls.deleteParticipantFromTeam}/${id}`,{
    //             method: 'DELETE',
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         })
    //         const result = await response.json();
    //         if(!response.ok){
    //             console.log("Failed to Delete Participant",result)
    //             toast.error("Failed to Delete Participant")
    //         }
    //         console.log("Participant Deleted Successfully",result)
    //         refetch();
    //         toast.success("Participant Deleted Successfully")
    //     }
    //     catch(error){
    //         console.log(error)
    //     }
    // }

    const columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            center: true,
            width: "80px"
        },
        {
            name: "Player Name",
            selector: row => (
                <div>
                    <p>{row?.name}</p>
                </div>
            ),
            // center: true,
            // grow: 2.5
        },
        {
            name:"Mobile Number",
            selector: row =>(
                <div>{row?.phone}</div>
            ),
            center: true
        },
        {
            name:"Email",
            selector: row =>(
                <div>{row?.email}</div>
            )
        },
        {
            name: "Date Added",
            selector: row => (
                <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
            ),
            // center: true
        },
        // {
        //     name: "Goals",
        //     selector: row => (
        //         <p>0</p>
        //     ),
        //     // center: true
        // },
        // {
        //     name: "Assists",
        //     selector: row => (
        //         <p>0</p>
        //     ),
        //     // center: true
        // },
        // {
        //     name: "Yellow Cards",
        //     selector: row => (
        //         <p>0</p>
        //     ),
        //     // center: true
        // },
        // {
        //     name: "Red Cards",
        //     selector: row => (
        //         <p>0</p>
        //     ),
        //     // center: true
        // },
        // {
        //     name: "Total Score",
        //     selector: row => (
        //         <p>0</p>
        //     ),
        //     // center: true
        // },
        {
            name: "Action",
            selector: row => (
                <div
                    className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-md p-2 cursor-pointer"
                    onClick={() => handleDeletePlayer(row?._id)}
                >
                    <Icon icon="material-symbols:delete-rounded" className="text-[1.5rem]" />
                </div>
            ),
            // center: true
        }
    ]

    const customStyles = {
        headRow: {
            style: {
                fontWeight: 600,
                color: "#4D4D4D",
                fontSize: "13px",
                // background: "#EAECF0"
            },
        },
        head: {
            style: {
                background: "#EAECF0",
            },
        },

        rows: {
            style: {
                background: "#FFF",
                color: "#565656",
                padding: "5px 0px",
                fontSize: "14px",
                fontWeight: 400,
                width: "auto",
            },
        },
        table: {
            style: {
                overflow: "visible",
                minWidth: "1100px",
            },
        },
        tableWrapper: {
            style: {
                overflow: "visible",
            },
        },
        responsiveWrapper: {
            style: {
                overflowX: "auto",
            },
        },
    };

    const handleShowModal = () => {
        setParticipantModal(true)
    }

    const handleDeletePlayer = async (id) => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.removePlayerFromTeam}/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                console.error(result.message)
                toast.error(result.message)
            }
            console.log("Player Deleted Successfully in Team", result.message)
            refetch();
            toast.success("Player Deleted Successfully")
            return;
        } catch (error) {
            console.log("Error in Deleting the Player in Team", error)
        }

    }

    return (
        <>
            <div className='flex items-center gap-1 p-2 bg-white my-5 rounded-md'>
                <p className='text-base font-semibold text-[#808080] cursor-pointer'
                    onClick={() => navigate("/tournaments")}
                >
                    Tournament
                </p>
                <PiGreaterThan className='text-base font-semibold text-[#808080] cursor-pointer' />
                <div className='flex items-center cursor-pointer'
                    onClick={() => navigate(`/tournaments/tournamentDetails/${tournamentId}`)}
                >
                    <p className='text-base font-semibold text-[#808080]'>{tournamentId}</p>
                </div>
                <PiGreaterThan className='text-base font-semibold text-[#808080]' />
                <p className='text-base font-semibold text-[#808080] cursor-pointer'
                    onClick={() => navigate(`/tournaments/tournamentDetails/${tournamentId}/team_creation`)}
                >
                    Creation of Teams
                </p>
                <PiGreaterThan className='text-base font-semibold text-[#808080]' />
                <p className='text-lg font-bold'>{teamDetails?.name}</p>
            </div>
            <div className='bg-white rounded-md'>
                <div className='flex gap-[3rem] border-b-2 border-[#989898] pt-[0.5rem] ml-2'>
                    <div className={`${activeParticipants === "participants" ? `font-bold text-primary border-b-2 border-primary` : "text-[#858585]"} cursor-pointer text-xl text-[1.2rem] sm:text-[1.3rem] font-bold pb-[0.3rem]`} onClick={() => setActivePartcipants("participants")}>
                        <h3>Participants</h3>
                    </div>
                </div>

                <div className='flex items-center justify-between px-5 py-5'>
                    <h4 className='text-[#000000] font-semibold text-xl'>Participants</h4>
                    <div className='flex items-center gap-4'>
                        <button className='bg-primary px-4 py-2 text-white flex items-center rounded-md gap-1'
                            onClick={handleShowModal}
                        >
                            Add Partcipant
                            <AiOutlinePlus className="text-[1.2rem]" />
                        </button>
                    </div>
                </div>
                <ToastContainer />
                <DataTable
                    columns={columns}
                    data={teamDetails?.players}
                    customStyles={customStyles}
                    selectableRows
                    progressPending={isLoading}
                    pagination
                    paginationPerPage={10}
                />
            </div>
            {participantModal &&
                <AddParticipantModal
                    setParticipantModal={setParticipantModal}
                    teamID={teamId}
                    refetch={refetch}
                />
            }
        </>
    )
}
