import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { LuPlus } from "react-icons/lu";
import { useAuth } from "../../../store/AuthContext";
import { APIurls } from "../../../api/apiConstant";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import trophy from "../../../assets/winnerImg.jpg";
import { RiDeleteBinFill } from "react-icons/ri";
import { useEffect } from "react";
import ChangesSavePopupModel from "./onChangeScoreModel.jsx";

export default function AddScoreGameFixturesModal({
  setAddScoreModal,
  matchID,
  status,
  setIsRefetch,
  tournamentType,
}) {
  const { getAccessToken } = useAuth();
  const [showError, setShowError] = useState(false);
  const [winner, setWinner] = useState("");
  const [announceWinner, setAnnounceWinner] = useState(false);
  const [noOfSets, setNoOfSets] = useState(1);
  const [participantsScores, setParticipantsScores] = useState([{}]);
  const [winsA, setWinsA] = useState(0);
  const [winsB, setWinsB] = useState(0);
  const [isSavingScores, setIsSavingScores] = useState(false);
  const [winnerPlaceholder, setWinnerPlaceholder] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [cricketWinnerStatus, setCricketWinnerStatus] = useState("");
  const [cricketWinner, setCricketWinner] = useState("");
  const [cricketResultObj, setCricketResultOb] = useState({
    winByNumber: 0,
    winByName: "",
  });
  const [changesSaveModel, setChangesSaveModel] = useState(false);
  const [onSaveModel, setOnSaveModel] = useState(false);

  const fetchMatchDetails = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchMatchDetailsById}/${matchID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    setWinner(result?.response?.matchDetails?.winnerID);
    if (
      result?.response?.matchDetails?.teamA === null &&
      result?.response?.matchDetails?.teamB === null
    ) {
      setAddScoreModal(false);
      toast.error("Participants are not assigned in this match");
    }
    const matchDetails = result?.response?.matchDetails;
    console.log("MATCH_DETAILS : ", matchDetails);
    console.log("MATCH_DETAILS  sets: ", matchDetails?.noOfSets);
    setNoOfSets(matchDetails?.noOfSets);
    const obj = {};
    obj.winByNumber = matchDetails?.sportStatusNumber;
    obj.winByName = matchDetails?.sportStatusName;
    setCricketResultOb(obj);
    setCricketWinnerStatus(matchDetails?.sportResult);
    // setWinsA(matchDetails?.winsA)
    // setWinsB(matchDetails?.winsB)
    const scoreA = matchDetails?.scoreA;
    const scoreB = matchDetails?.scoreB;
    const scoreAB = scoreA?.map((value, index) => {
      let setWinner =
        value > scoreB[index] ? matchDetails?.teamA : matchDetails?.teamB;
      if (value === scoreB[index]) {
        setWinner = "Draw";
      }
      return { pointsA: value, pointsB: scoreB[index], winner: setWinner };
    });
    console.log("scoreAB : ", scoreAB);
    console.log("lets test it");
    let cricketScore = [];

    cricketScore = matchDetails?.scoreA?.map((value, index) => {
      if (index === 0) {
        const winTeam =
          value > scoreB[index]
            ? matchDetails?.teamA
            : value < scoreB[index]
            ? matchDetails?.teamB
            : "";
        setCricketWinner(winTeam);
      }
      return { pointsA: value, pointsB: scoreB[index] };
    });
    console.log("cricket data 1 : ", cricketScore);
    if (matchDetails?.wicketsA.length === 0) {
      cricketScore = cricketScore?.map((value) => {
        // if(value?.pointsA > value?.pointsB){
        //     setCricketWinner(matchDetails?.teamA);
        // }
        // if(value?.pointsB > value?.pointsA){
        //     setCricketWinner(matchDetails?.teamB);
        // }
        return {
          pointsA: value?.pointsA,
          pointsB: value?.pointsB,
          wicketsA: 0,
          wicketsB: 0,
          oversA: 0,
          oversB: 0,
          totalOversA: 0,
          totalOversB: 0,
        };
      });
    } else {
      matchDetails?.wicketsA?.map((value, index) => {
        if (!cricketScore[index]) {
          cricketScore[index] = { pointsA: 0, pointsB: 0 };
        }
        cricketScore[index].wicketsA = value;
        cricketScore[index].wicketsB = matchDetails?.wicketsB[index];
        cricketScore[index].oversA = matchDetails?.oversA[index];
        cricketScore[index].oversB = matchDetails?.oversB[index];
        cricketScore[index].totalOversA = matchDetails?.totalOvers[index];
        cricketScore[index].totalOversB = matchDetails?.totalOvers[index];
        return value;
      });
    }
    // matchDetails?.wicketsA?.map((value))
    console.log("after score added for cricket : ", cricketScore);
    if (matchDetails?.scoreType === "Best Of") {
      const bestOfWins = Math.round(matchDetails?.noOfSets / 2);
      if (scoreAB.length >= matchDetails?.noOfSets) {
        console.log("best of equal :", bestOfWins);
        if (matchDetails?.winsA >= bestOfWins) {
          console.log("WINNER :", matchDetails?.teamA);
          setWinnerPlaceholder(matchDetails?.teamA);
        }
        if (matchDetails?.winsB >= bestOfWins) {
          console.log("WINNER :", matchDetails?.teamA);
          setWinnerPlaceholder(matchDetails?.teamB);
        }
        if (
          matchDetails?.winsA < bestOfWins &&
          matchDetails?.winsB < bestOfWins
        ) {
          setWinnerPlaceholder("");
        }
      }
      if (
        matchDetails?.winsA < bestOfWins &&
        matchDetails?.winsB < bestOfWins
      ) {
        console.log(
          "not equal : ",
          bestOfWins,
          matchDetails?.winsA,
          matchDetails?.winsB
        );
        setWinnerPlaceholder("");
      }
    }
    if (matchDetails?.scoreType === "Race Of") {
      if (matchDetails?.winsA === matchDetails?.noOfSets) {
        setWinnerPlaceholder(matchDetails?.teamA);
      }
      if (matchDetails?.winsB === matchDetails?.noOfSets) {
        setWinnerPlaceholder(matchDetails?.teamB);
      }
      if (
        matchDetails?.winsA !== matchDetails?.noOfSets &&
        matchDetails?.winsB !== matchDetails?.noOfSets
      ) {
        setWinnerPlaceholder("");
      }
    }
    if (matchDetails?.scoreType === "Top Score") {
      const participantA_TopScore = scoreAB.reduce(
        (acc, setObj) => (acc += setObj?.pointsA),
        0
      );
      const participantB_TopScore = scoreAB.reduce(
        (acc, setObj) => (acc += setObj?.pointsB),
        0
      );
      console.log("teamA :", participantA_TopScore);
      console.log("teamB :", participantB_TopScore);
      if (participantA_TopScore > participantB_TopScore) {
        setWinnerPlaceholder(matchDetails?.teamA);
      }
      if (participantA_TopScore < participantB_TopScore) {
        setWinnerPlaceholder(matchDetails?.teamB);
      }
      if (participantA_TopScore === participantB_TopScore) {
        setWinnerPlaceholder("");
      }
      setWinsA(participantA_TopScore);
      setWinsB(participantB_TopScore);
    }
    if (matchDetails?.scoreType !== "Top Score") {
      setWinsA(matchDetails?.winsA);
      setWinsB(matchDetails?.winsB);
    }
    if (matchDetails?.scoreType === "Others") {
      if (matchDetails?.sportName?.toLowerCase().includes("cricket")) {
        console.log("cricket score : ", cricketScore);
        if (cricketScore[0]["pointsA"] < cricketScore[0]["pointsB"]) {
          setWinnerPlaceholder(matchDetails?.teamB);
        }
        if (cricketScore[0]["pointsA"] > cricketScore[0]["pointsB"]) {
          setWinnerPlaceholder(matchDetails?.teamA);
        }
        if (cricketScore[0]["pointsA"] === cricketScore[0]["pointsB"]) {
          setWinnerPlaceholder("");
        }
        setParticipantsScores(cricketScore);
      }
    } else {
      setParticipantsScores(scoreAB);
    }
    return result?.response?.matchDetails;
  };

  const { data: matchDetailsData, refetch } = useQuery(
    "fetchMatchData",
    fetchMatchDetails
  );

  useEffect(() => {
    refetch();
  }, [isSaving, refetch]);
  const handleSaveSetNumber = async () => {
    try {
      setIsSaving(true);
      const token = await getAccessToken();
      console.log('"token : ', token);
      console.log('"matchID : ', matchDetailsData?.id);
      const response = await fetch(
        `${APIurls.updateTournamentMatchSetsNumber}/${matchDetailsData?.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ numberOfSets: noOfSets }),
        }
      );

      const result = await response.json();
      console.log("on save number");
      if (result?.code > 200) {
        console.log("noOfSets not updated ", result?.message);
        toast.error(result?.message);
        // setAddScoreModal(false);
        return;
      }
      console.log("noOfSets Updated Successfully", result);
      toast.success("Number Of Sets Updated Successfully");
      setIsSaving(false);
      // setIsRefetch(true);
      // setAddScoreModal(false);
      setChangesSaveModel(false);
      return;
    } catch (error) {
      setIsSaving(false);
      toast.error(error?.message);
      console.log("Error in saving set Number" + error?.message);
    }
  };
  const handleAnnounceWinner = async (winner) => {
    if (!winner) {
      setShowError(true);
      return;
    }
    try {
      setAnnounceWinner(true);
      const token = await getAccessToken();
      const url =
        tournamentType === "Knockout"
          ? APIurls.updateWinnerById
          : tournamentType === "Double Elimination Bracket"
          ? APIurls.updateWinnerForDoubleKnockoutById
          : APIurls.updateWinnerForRoundRobbinById;
      const response = await fetch(`${url}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ matchID: matchID, winnerID: winner }),
      });
      const result = await response.json();
      if (result?.code > 201) {
        console.log("Winner not updated ", result?.message);
        toast.error(result?.message);
        setAddScoreModal(false);
        return;
      }
      console.log("Winner Updated Successfully", result);
      toast.success("Winner Updated Successfully");
      setAnnounceWinner(false);
      setIsRefetch(true);
      setAddScoreModal(false);
      return;
    } catch (error) {
      setAnnounceWinner(false);
      console.log("Error in Updating Winner", error);
    }
  };

  const handleAddSetScore = () => {
    const obj = {
      pointsA: 0,
      pointsB: 0,
      winner: "",
    };
    console.log("before adding length : ", participantsScores.length);
    setParticipantsScores((prevScore) => [...prevScore, obj]);
    setChangesSaveModel(true);
  };

  const handleScoreInput = (e, i, side) => {
    console.log("Input for--->", i, e.target.value);
    const updatedScore = [...participantsScores];
    if (side === "A") {
      updatedScore[i].pointsA = parseInt(e.target.value);
    } else if (side === "B") {
      updatedScore[i].pointsB = parseInt(e.target.value);
    }
    if (updatedScore[i]?.pointsA > updatedScore[i]?.pointsB) {
      updatedScore[i].winner = matchDetailsData?.teamA;
    } else {
      updatedScore[i].winner = matchDetailsData?.teamB;
    }
    if (updatedScore[i]?.pointsA === updatedScore[i]?.pointsB) {
      updatedScore[i].winner = "Draw";
    }
    const AWins = updatedScore?.reduce((acc, obj) => {
      if (obj.winner === matchDetailsData?.teamA) {
        acc++;
      }
      return acc;
    }, 0);
    const BWins = updatedScore?.reduce((acc, obj) => {
      if (obj.winner === matchDetailsData?.teamB) {
        acc++;
      }
      return acc;
    }, 0);
    if (matchDetailsData?.scoreType === "Best Of") {
      const bestOfWins = Math.round(matchDetailsData?.noOfSets / 2);
      if (updatedScore.length >= matchDetailsData?.noOfSets) {
        console.log("best of equal :", bestOfWins);
        if (AWins >= bestOfWins) {
          console.log("WINNER :", matchDetailsData?.teamA);
          setWinnerPlaceholder(matchDetailsData?.teamA);
        }
        if (BWins >= bestOfWins) {
          console.log("WINNER :", matchDetailsData?.teamA);
          setWinnerPlaceholder(matchDetailsData?.teamB);
        }
        if (AWins < bestOfWins && BWins < bestOfWins) {
          setWinnerPlaceholder("");
        }
      }
      if (AWins < bestOfWins && BWins < bestOfWins) {
        console.log("not equal : ", bestOfWins, AWins, BWins);
        setWinnerPlaceholder("");
      }
    }
    if (matchDetailsData?.scoreType === "Race Of") {
      if (AWins === matchDetailsData?.noOfSets) {
        setWinnerPlaceholder(matchDetailsData?.teamA);
      }
      if (BWins === matchDetailsData?.noOfSets) {
        setWinnerPlaceholder(matchDetailsData?.teamB);
      }
      if (
        AWins !== matchDetailsData?.noOfSets &&
        BWins !== matchDetailsData?.noOfSets
      ) {
        setWinnerPlaceholder("");
      }
    }
    if (matchDetailsData?.scoreType === "Top Score") {
      const participantA_TopScore = updatedScore.reduce(
        (acc, setObj) => (acc += setObj?.pointsA),
        0
      );
      const participantB_TopScore = updatedScore.reduce(
        (acc, setObj) => (acc += setObj?.pointsB),
        0
      );
      console.log("teamA :", participantA_TopScore);
      console.log("teamB :", participantB_TopScore);
      if (participantA_TopScore > participantB_TopScore) {
        setWinnerPlaceholder(matchDetailsData?.teamA);
      }
      if (participantA_TopScore < participantB_TopScore) {
        setWinnerPlaceholder(matchDetailsData?.teamB);
      }
      if (participantA_TopScore === participantB_TopScore) {
        setWinnerPlaceholder("");
      }
      setWinsA(participantA_TopScore);
      setWinsB(participantB_TopScore);
    }
    if (matchDetailsData?.scoreType !== "Top Score") {
      setWinsA(AWins);
      setWinsB(BWins);
    }
    setParticipantsScores(updatedScore);
    setChangesSaveModel(true);
  };

  const handleSetDelete = (i) => {
    console.log("index : ", i);
    console.log("before :", participantsScores);
    const filteredSetData = participantsScores?.filter(
      (value, index) => index !== i
    );
    console.log("data filtered :", filteredSetData);
    const AWins = filteredSetData?.reduce((acc, obj) => {
      if (obj.winner === matchDetailsData?.teamA) {
        acc++;
      }
      return acc;
    }, 0);
    const BWins = filteredSetData?.reduce((acc, obj) => {
      if (obj.winner === matchDetailsData?.teamB) {
        acc++;
      }
      return acc;
    }, 0);
    if (matchDetailsData?.scoreType === "Best Of") {
      const bestOfWins = Math.round(matchDetailsData?.noOfSets / 2);
      if (filteredSetData.length >= matchDetailsData?.noOfSets) {
        if (AWins >= bestOfWins) {
          setWinnerPlaceholder(matchDetailsData?.teamA);
        }
        if (BWins >= bestOfWins) {
          setWinnerPlaceholder(matchDetailsData?.teamB);
        }
        if (AWins < bestOfWins && BWins < bestOfWins) {
          console.log("not equal : ", bestOfWins, AWins, BWins);
          setWinnerPlaceholder("");
        }
      }
      if (AWins < bestOfWins && BWins < bestOfWins) {
        console.log("not equal : ", bestOfWins, AWins, BWins);
        setWinnerPlaceholder("");
      }
    }
    if (matchDetailsData?.scoreType === "Race Of") {
      if (AWins === matchDetailsData?.noOfSets) {
        setWinnerPlaceholder(matchDetailsData?.teamA);
      }
      if (BWins === matchDetailsData?.noOfSets) {
        setWinnerPlaceholder(matchDetailsData?.teamB);
      }
      if (
        AWins !== matchDetailsData?.noOfSets &&
        BWins !== matchDetailsData?.noOfSets
      ) {
        setWinnerPlaceholder("");
      }
    }
    if (matchDetailsData?.scoreType === "Top Score") {
      const participantA_TopScore = filteredSetData.reduce(
        (acc, setObj) => (acc += setObj?.pointsA),
        0
      );
      const participantB_TopScore = filteredSetData.reduce(
        (acc, setObj) => (acc += setObj?.pointsB),
        0
      );
      console.log("teamA :", participantA_TopScore);
      console.log("teamB :", participantB_TopScore);
      if (participantA_TopScore > participantB_TopScore) {
        setWinnerPlaceholder(matchDetailsData?.teamA);
      }
      if (participantA_TopScore < participantB_TopScore) {
        setWinnerPlaceholder(matchDetailsData?.teamB);
      }
      if (participantA_TopScore === participantB_TopScore) {
        setWinnerPlaceholder("");
      }
      setWinsA(participantA_TopScore);
      setWinsB(participantB_TopScore);
    }
    if (matchDetailsData?.scoreType !== "Top Score") {
      setWinsA(AWins);
      setWinsB(BWins);
    }
    setParticipantsScores(filteredSetData);
    setChangesSaveModel(true);
  };

  const onSubmitScoreHandler = async () => {
    try {
      console.log("saving score");

      let participantsA_score = [];
      let participantsB_score = [];
      participantsScores?.map((scores) => {
        participantsA_score.push(scores?.pointsA);
        participantsB_score.push(scores?.pointsB);
        return scores;
      });
      setIsSavingScores(true);
      const token = await getAccessToken();
      const payload = {
        scoreA: participantsA_score,
        scoreB: participantsB_score,
        winsA: winsA,
        winsB: winsB,
        scoreType: matchDetailsData?.scoreType,
      };
      const response = await fetch(
        `${APIurls.updateTournamentMatchScores}/${matchID}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      if (result?.code > 200) {
        setIsSavingScores(false);
        console.log("scores not updated ", result?.message);
        toast.error(result?.message);
        return;
      }
      console.log("scores Updated Successfully", result);
      toast.success("Scores Updated Successfully");
      setIsSavingScores(false);
      setChangesSaveModel(false);
      return;
    } catch (error) {
      toast.error(error?.message);
      setIsSavingScores(false);
      console.log("error in saving score" + error?.message);
    }
  };

  const handleCricketScoreInput = (event, index, participant) => {
    // setParticipantsScores
    const newScores = [...participantsScores];
    console.log(
      "events : ",
      typeof event.target.value,
      event.target.value,
      index,
      participant
    );
    newScores[index][event.target.name] = +event.target.value;
    if (newScores[index]["pointsA"] > newScores[index]["pointsB"]) {
      setCricketWinner(matchDetailsData?.teamA);
      setWinnerPlaceholder(matchDetailsData?.teamA);
    }
    if (newScores[index]["pointsA"] < newScores[index]["pointsB"]) {
      setCricketWinner(matchDetailsData?.teamB);
      setWinnerPlaceholder(matchDetailsData?.teamB);
    }
    if (newScores[index]["pointsA"] === newScores[index]["pointsB"]) {
      setCricketWinner("");
      setWinnerPlaceholder("");
    }
    if (event.target.name === "pointsA" || event.target.name === "pointsB") {
      setCricketWinnerStatus("");
    }

    setParticipantsScores([...newScores]);
    setChangesSaveModel(true);
  };
  //   const handleCricketScoreDelete = (index) => {
  //     let newScores = [...participantsScores];
  //     newScores = newScores?.filter((value, idx) => idx !== index);
  //     setParticipantsScores([...newScores]);
  //   };
  //   const addCricketScoreSet = () => {
  //     const obj = {
  //       pointsA: 0,
  //       pointsB: 0,
  //       wicketsA: 0,
  //       wicketsB: 0,
  //       oversA: 0,
  //       oversB: 0,
  //       totalOversA: 0,
  //       totalOversB: 0,
  //     };
  //     const newScores = [...participantsScores];
  //     newScores.push(obj);
  //     setParticipantsScores([...newScores]);
  //   };

  const finalResultHandler = (event, position) => {
    console.log("event of w and R : ", event, position);
    const obj = { ...cricketResultObj };
    if (position === "i") {
      obj.winByNumber = +event.target.value;
    }
    if (position === "W") {
      obj.winByName = "Wickets";
    }
    if (position === "R") {
      obj.winByName = "Runs";
    }
    // cricketWinner
    // setCricketWinnerStatus
    if (cricketWinner.length > 0) {
      if (obj?.winByNumber > 0) {
        if (obj?.winByName.length > 0) {
          setCricketWinnerStatus(
            `${cricketWinner} Won By ${obj?.winByNumber} ${obj?.winByName} `
          );
        }
      } else {
        setCricketWinnerStatus("");
      }
    } else {
      setCricketWinnerStatus("");
    }
    setCricketResultOb(obj);
    setChangesSaveModel(true);
  };
  const onSubmitCricketScoreHandler = async () => {
    // participantsScores
    // setIsSavingScores
    // cricketWinnerStatus
    // cricketResultObj
    // updateTournamentMatchCricketScores
    const scoresData = {
      scoreA: +participantsScores[0].pointsA,
      scoreB: +participantsScores[0].pointsB,
      wicketsA: +participantsScores[0].wicketsA,
      wicketsB: +participantsScores[0].wicketsB,
      oversA: +participantsScores[0].oversA,
      oversB: +participantsScores[0].oversB,
      totalOvers: +participantsScores[0].totalOversA,
      sportStatusNumber: +cricketResultObj?.winByNumber,
      sportStatusName: cricketResultObj?.winByName,
      sportResult: cricketWinnerStatus,
      scoreType: matchDetailsData?.scoreType,
    };
    try {
      console.log("payload : ", scoresData);
      setIsSavingScores(true);
      const token = await getAccessToken();
      const response = await fetch(
        `${APIurls.updateTournamentMatchCricketScores}/${matchID}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(scoresData),
        }
      );
      const result = await response.json();
      console.log("result : ", result);
      if (result.code > 200) {
        setIsSavingScores(false);
        throw new Error(result.message);
      }
      console.log("scores Updated Successfully", result);
      toast.success("Scores Updated Successfully");
      setIsSavingScores(false);
      setChangesSaveModel(false);
      return;
    } catch (error) {
      toast.error(error?.message);
      setIsSavingScores(false);
      console.log("Error in saving cricket scores :" + error?.message);
    }
  };
  const onCancelScoreModel = () => {
    if (changesSaveModel) {
      setOnSaveModel(true);
    } else {
      setAddScoreModal(false);
    }
    return;
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6] w-[100%]">
      <div
        className={`${
          status === false ? "w-[40%]" : "w-[60%]"
        } flex flex-col gap-2 bg-white rounded-sm px-6 py-8 overflow-y-auto h-[80%]`}
      >
        <div className="flex justify-end items-center">
          <div
            // onClick={() => setAddScoreModal(false)}
            onClick={() => onCancelScoreModel()}
            className={
              "shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm cursor-pointer"
            }
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </div>
        </div>
        <div className="text-center font-semibold text-primary ">
          {matchDetailsData?.sportName}
        </div>
        {/* ScoreType */}
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <p className="text-lg font-semibold px-2">
              {matchDetailsData?.scoreType}
            </p>
            {matchDetailsData?.scoreType === "Best Of" && (
              <div>
                :-{" "}
                <input
                  type="number"
                  className=" custom-number bg-[#EFF1F9] text-center w-[30%] border rounded-md  outline-none px-2 py-1 "
                  value={noOfSets}
                  onChange={(e) => setNoOfSets(+e.target.value)}
                />
              </div>
            )}
            {matchDetailsData?.scoreType === "Race Of" && (
              <div>
                :-{" "}
                <input
                  type="number"
                  className=" custom-number bg-[#EFF1F9] text-center w-[30%] border rounded-md outline-none px-2 py-1 "
                  value={noOfSets}
                  onChange={(e) => setNoOfSets(+e.target.value)}
                />
              </div>
            )}
          </div>
          {matchDetailsData?.scoreType === "Best Of" && (
            <button
              onClick={handleSaveSetNumber}
              className={`flex justify-center items-center text-white px-3 py-1 ${
                matchDetailsData?.noOfSets !== noOfSets
                  ? "bg-green-600"
                  : "bg-primary"
              }   rounded-md`}
            >
              {matchDetailsData?.noOfSets === noOfSets ? "" : "*"}
              {isSaving ? "Saving... " : "Save "} <PiGreaterThan />
            </button>
          )}
          {matchDetailsData?.scoreType === "Race Of" && (
            <button
              onClick={handleSaveSetNumber}
              className={`flex justify-center items-center text-white px-3 py-1 ${
                matchDetailsData?.noOfSets !== noOfSets
                  ? "bg-green-600"
                  : "bg-primary"
              } rounded-md`}
            >
              {matchDetailsData?.noOfSets === noOfSets ? "" : "*"}
              {isSaving ? "Saving... " : "Save "} <PiGreaterThan />
            </button>
          )}
        </div>
        {/* For Sport Name */}
        {matchDetailsData?.scoreType === "Others" &&
          matchDetailsData?.sportName?.toLowerCase().includes("cricket") && (
            <div className="flex flex-col gap-[1rem] w-[100%]">
              {/* <div className="flex justify-end items-center">
                <button
                  className="w-[160px] flex justify-center items-center text-white bg-primary px-3 py-1 rounded-md"
                  // onClick={addCricketScoreSet}
                >
                  Add match Set <LuPlus />
                </button>
              </div> */}
              <div className="px-4 py-4 rounded-md border border-[#EAECF0] w-[100%]">
                <div className="flex justify-center gap-[3rem] pb-3">
                  <div className="flex items-center flex-col gap-[2.5rem]">
                    <p className="text-lg font-semibold">Participants</p>
                    <p className="text-base py-1">{matchDetailsData?.teamA}</p>
                    <p className="text-base py-1">{matchDetailsData?.teamB}</p>
                  </div>
                  <div className="overflow-x-auto w-[80%]">
                    <div className="flex w-[200px] gap-[1rem] ">
                      {participantsScores?.map((div, i) => {
                        return (
                          <div className="flex flex-col gap-[1rem] ">
                            <div className="flex justify-evenly items-center">
                              <p className="text-lg font-semibold text-start">
                                Match{" "}
                                {participantsScores.length === 1 ? "" : i + 1}
                              </p>
                              {participantsScores.length > 1 && (
                                <RiDeleteBinFill
                                  className="text-[1.3rem] text-primary cursor-pointer"
                                  // onClick={() => handleCricketScoreDelete(i)}
                                ></RiDeleteBinFill>
                              )}
                            </div>
                            <div className="flex gap-2">
                              <div className="flex flex-col gap-1">
                                <p className="text-center font-semibold">
                                  Score
                                </p>
                                <input
                                  type="number"
                                  name="pointsA"
                                  value={div?.pointsA}
                                  placeholder="Enter Score"
                                  className=" bg-[#EFF1F9] w-[100px]  custom-number text-center outline-none px-2 py-1 "
                                  onChange={(e) =>
                                    handleCricketScoreInput(e, i, "A")
                                  }
                                />
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-center font-semibold">
                                  Wickets
                                </p>
                                <input
                                  type="number"
                                  name="wicketsA"
                                  value={div?.wicketsA}
                                  placeholder="Enter Score"
                                  className=" bg-[#EFF1F9] w-[100px] custom-number text-center outline-none px-2 py-1 "
                                  onChange={(e) =>
                                    handleCricketScoreInput(e, i, "A")
                                  }
                                />
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-center font-semibold">
                                  Overs
                                </p>
                                <input
                                  type="number"
                                  name="oversA"
                                  value={div?.oversA}
                                  placeholder="Enter Score"
                                  className=" bg-[#EFF1F9] w-[100px] custom-number text-center outline-none px-2 py-1 "
                                  onChange={(e) =>
                                    handleCricketScoreInput(e, i, "A")
                                  }
                                />
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-center font-semibold">
                                  Total Overs
                                </p>
                                <input
                                  type="number"
                                  name="totalOversA"
                                  value={div?.totalOversA}
                                  placeholder="Enter Score"
                                  className=" bg-[#EFF1F9] w-[100px] custom-number text-center outline-none px-2 py-1 "
                                  onChange={(e) =>
                                    handleCricketScoreInput(e, i, "A")
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex gap-2">
                              <div className="flex flex-col gap-1">
                                <p className="text-center font-semibold">
                                  Score
                                </p>
                                <input
                                  type="number"
                                  name="pointsB"
                                  value={div?.pointsB}
                                  placeholder="Enter Score"
                                  className=" bg-[#EFF1F9] w-[100px] custom-number text-center outline-none px-2 py-1 "
                                  onChange={(e) =>
                                    handleCricketScoreInput(e, i, "B")
                                  }
                                />
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-center font-semibold">
                                  Wickets
                                </p>
                                <input
                                  type="number"
                                  name="wicketsB"
                                  value={div?.wicketsB}
                                  placeholder="Enter Score"
                                  className=" bg-[#EFF1F9] w-[100px] custom-number text-center outline-none px-2 py-1 "
                                  onChange={(e) =>
                                    handleCricketScoreInput(e, i, "B")
                                  }
                                />
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-center font-semibold">
                                  Overs
                                </p>
                                <input
                                  type="number"
                                  name="oversB"
                                  value={div?.oversB}
                                  placeholder="Enter Score"
                                  className=" bg-[#EFF1F9] w-[100px] custom-number text-center outline-none px-2 py-1 "
                                  onChange={(e) =>
                                    handleCricketScoreInput(e, i, "B")
                                  }
                                />
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-center font-semibold">
                                  Total Overs
                                </p>
                                <input
                                  type="number"
                                  name="totalOversB"
                                  value={div?.totalOversB}
                                  placeholder="Enter Score"
                                  className=" bg-[#EFF1F9] w-[100px] custom-number text-center outline-none px-2 py-1 "
                                  onChange={(e) =>
                                    handleCricketScoreInput(e, i, "B")
                                  } // onChange={(e) => handleScoreInput(e, i, "A")}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="text-center flex items-center justify-center gap-2">
                  <p className="text-center font-semibold">
                    {" "}
                    {cricketWinner.length > 0
                      ? `${cricketWinner}   `
                      : "Participant "}
                  </p>
                  <span>Won By</span>
                  <input
                    type="number"
                    value={cricketResultObj.winByNumber}
                    defaultValue={0}
                    min="0"
                    onChange={(e) => finalResultHandler(e, "i")}
                    className="bg-[#EFF1F9] w-[100px]  custom-number text-center outline-none px-2 py-1 "
                  />
                  <div className="w-[100px] flex justify-center items-center rounded-md border border-[#D9D9D9] gap-1 px-2 py-2">
                    <input
                      type="radio"
                      checked={
                        cricketResultObj?.winByName === "Wickets" ? true : false
                      }
                      onChange={(e) => finalResultHandler(e, "W")}
                      name="defaultSponsor"
                      placeholder="Enter result"
                    />
                    <p className="text-[14px] text-[#858585]">Wickets</p>
                  </div>
                  <div className="w-[100px] flex justify-center items-center  rounded-md border border-[#D9D9D9] gap-1 px-2 py-2">
                    <input
                      type="radio"
                      checked={
                        cricketResultObj?.winByName === "Runs" ? true : false
                      }
                      onChange={(e) => finalResultHandler(e, "R")}
                      name="defaultSponsor"
                      placeholder="Enter result"
                    />
                    <p className="text-[14px] text-[#858585]">Runs</p>
                  </div>
                </div>
                <div className="text-center font-semibold mt-2 h-6">
                  {cricketWinnerStatus ? cricketWinnerStatus : ""}
                </div>
                <div className="flex justify-end items-center mt-4">
                  <button
                    className={`flex justify-center items-center text-white px-3 py-1 ${
                      isSavingScores ? "bg-green-600" : "bg-primary"
                    }   rounded-md`}
                    onClick={onSubmitCricketScoreHandler}
                  >
                    {isSavingScores ? "Saving score ..." : "Save score"}{" "}
                    <PiGreaterThan />
                  </button>
                </div>
              </div>
            </div>
          )}

        {/* Others Sports Score Apart from Cricket */}
        {matchDetailsData?.scoreType !== "Others" && (
          <div className="flex flex-col gap-[1rem] w-[100%]">
            <div className="flex justify-end items-center">
              <button
                className="w-[160px] flex justify-center items-center text-white bg-primary px-3 py-1 rounded-md"
                onClick={handleAddSetScore}
              >
                Add Set Score <LuPlus />
              </button>
            </div>
            <div className="px-4 py-4 rounded-md border border-[#EAECF0] w-[100%]">
              <div className="flex justify-center gap-[3rem] pb-3">
                <div className="flex items-center flex-col gap-[1rem]">
                  <p className="text-lg font-semibold">Participants</p>
                  <p className="text-base py-1">{matchDetailsData?.teamA}</p>
                  <p className="text-base py-1">{matchDetailsData?.teamB}</p>
                </div>
                <div className="overflow-x-auto w-[80%]">
                  <div className="flex w-[200px] gap-[1rem] ">
                    {participantsScores?.map((div, i) => {
                      return (
                        <div className="w-[100px] flex flex-col gap-[1rem] ">
                          <div className="flex justify-evenly items-center">
                            <p className="text-lg font-semibold text-start">
                              Set {participantsScores.length === 1 ? "" : i + 1}
                            </p>
                            {participantsScores.length > 1 && (
                              <RiDeleteBinFill
                                className="text-[1.3rem] text-primary cursor-pointer"
                                onClick={() => handleSetDelete(i)}
                              ></RiDeleteBinFill>
                            )}
                          </div>
                          <input
                            type="number"
                            name="score"
                            value={div?.pointsA}
                            placeholder="Enter Score"
                            className=" bg-[#EFF1F9] custom-number text-center outline-none px-2 py-1 "
                            onChange={(e) => handleScoreInput(e, i, "A")}
                          />
                          <input
                            type="number"
                            value={div?.pointsB}
                            placeholder="Enter Score"
                            className=" bg-[#EFF1F9] custom-number text-center outline-none px-2  py-1"
                            onChange={(e) => handleScoreInput(e, i, "B")}
                          />
                          {matchDetailsData?.scoreType !== "Others" && (
                            <p className="text-center text-sm">{div?.winner}</p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {matchDetailsData?.scoreType !== "Others" && (
                <div>
                  <p className="text-center text-base font-semibold">
                    {" "}
                    {matchDetailsData?.scoreType === "Top Score"
                      ? "Top Scores"
                      : "Set Wins"}
                  </p>
                  <div className="flex justify-center items-center gap-[3rem]">
                    <p>
                      {`${matchDetailsData?.teamA} : `}
                      <span className="text-lg font-semibold">{winsA}</span>
                    </p>
                    <p>
                      {`${matchDetailsData?.teamB} : `}
                      <span className="text-lg font-semibold">{winsB}</span>
                    </p>
                  </div>
                </div>
              )}
              <div className="flex justify-end items-center mt-4">
                <button
                  className={`flex justify-center items-center text-white px-3 py-1 ${
                    isSavingScores ? "bg-green-600" : "bg-primary"
                  }   rounded-md`}
                  onClick={onSubmitScoreHandler}
                >
                  {isSavingScores ? "Saving score ..." : "Save score"}{" "}
                  <PiGreaterThan />
                </button>
              </div>
            </div>
          </div>
        )}
        {status === false ? (
          <div className="flex flex-col items-center justify-center gap-3">
            <img src={trophy} className="w-[40%]" alt="img" />
            <h3 className="text-white bg-[#3CB047] w-[30%] text-center font-semibold py-2 rounded-md">
              {matchDetailsData?.winner}
            </h3>
          </div>
        ) : (
          ""
        )}
        {status && (
          <div className="flex flex-col py-3 gap-3">
            <h2 className="font-semibold">Announce Winner</h2>
            <div className="flex items-center justify-evenly">
              <div className="w-[40%] ">
                <p
                  className={`${
                    winner === matchDetailsData?.teamA_id
                      ? "text-white bg-[#3CB047]"
                      : "bg-[#EFF1F9]"
                  } text-center font-semibold py-2 mb-2 rounded-md cursor-pointer`}
                  onClick={() => setWinner(matchDetailsData?.teamA_id)}
                >
                  {matchDetailsData?.teamA
                    ? matchDetailsData?.teamA
                    : "No Participant"}
                </p>
                <hr
                  className={`border-2 h-1 ${
                    winnerPlaceholder === matchDetailsData?.teamA &&
                    " border-primary bg-primary rounded"
                  }`}
                />
              </div>
              <div className="w-[40%]">
                <p
                  className={`${
                    winner === matchDetailsData?.teamB_id
                      ? "text-white bg-[#3CB047]"
                      : "bg-[#EFF1F9]"
                  } text-center font-semibold py-2 mb-2 rounded-md cursor-pointer`}
                  onClick={() => setWinner(matchDetailsData?.teamB_id)}
                >
                  {matchDetailsData?.teamB
                    ? matchDetailsData?.teamB
                    : "No Participant"}
                </p>
                <hr
                  className={`border-2 h-1 ${
                    winnerPlaceholder === matchDetailsData?.teamB &&
                    " border-primary bg-primary rounded"
                  }`}
                />
              </div>
            </div>
            {!winner && showError && (
              <p className="text-red-500 text-sm text-center">
                *This field is required
              </p>
            )}
            <div className="flex justify-end">
              <button
                className={`flex items-center text-white ${
                  announceWinner
                    ? "bg-green-700 cursor-not-allowed"
                    : "bg-[#FB6108] cursor-pointer"
                }  px-4 py-2 rounded-md gap-1 `}
                onClick={() => handleAnnounceWinner(winner)}
                disabled={announceWinner ? true : false}
              >
                {announceWinner ? "Announcing. . ." : "Announce Winner "}
                <PiGreaterThan />
              </button>
            </div>
          </div>
        )}
      </div>
      {/* {addSetScoreModal && (
                <AddSetScoreModal
                    setAddSetScoreModal={setAddSetScoreModal}
                />
            )} */}
      {changesSaveModel && onSaveModel && (
        <ChangesSavePopupModel
          setAddScoreModal={setAddScoreModal}
          setOnSaveModel={setOnSaveModel}
        />
      )}
    </div>
  );
}
