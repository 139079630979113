export const handleFirebaseError = (error) => {
      switch (error.code) {
        case "auth/email-already-in-use":
          return "The email address is already in use by another account.";
          break;
    
        case "auth/invalid-emailreturn":
          return "The email address is not valid."
          break;
    
        case "auth/operation-not-allowed":
          return "Email/password accounts are not enabled. Enable them in the Firebase Console.";
          break;
    
        case "auth/weak-password":
          return "The password is too weak.";
          break;
    
        case "auth/user-disabled":
          return "The user account has been disabled by an administrator.";
          break;
    
        case "auth/user-not-found":
          return "Email id is not registered with us";
          break;
    
        case "auth/wrong-password":
          return "The password is invalid for the given email.";
          break;
    
        case "auth/too-many-requests":
          return "Too many unsuccessful login attempts. Try again later.";
          break;
    
        // Additional errors
    
        case "auth/missing-iframe-start":
          return "An internal error has occurred."
          break;
    
        case "auth/invalid-login-credentials":
          return "Credentials are invalid."
          break;
  
          case "auth/invalid-credential":
            return "Credentials are invalid."
            break;  
    
        // Add more cases as needed for other error codes
    
        default:
          console.error("An error occurred:", error.message);
          break;
      }
    }