import React from "react";
import { Icon } from "@iconify/react";
export default function OverViewAddTeamModal({
  setAddScoreModal,
  setOnSaveModel,
}) {
  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
      <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[25%] relative">
        <div className="flex justify-between items-center">
          <h1 className="text-[20px] font-bold max-lg:text-[16px]">Score Changes not Save</h1>
          <div
            onClick={() => setOnSaveModel(false)}
            className={
              "shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm cursor-pointer"
            }
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </div>
        </div>
        <div className="w-[100%] h-[100%] text-red-600 bg-red-100 p-4 rounded-md">
          <p className="max-lg:text-[15px]">
            Changes in Scores are not save, Save the Changes Otherwise Changes
            will be gone !!
          </p>
        </div>
        <div className="w-[120px] flex items-center justify-center text-white bg-[#FB6108] px-2 py-2 cursor-pointer rounded-md gap-1">
          {
            <div className="flex justify-center items-center gap-1">
              <button
                type="button"
                className="text-[0.8rem] sm:text-[1rem] font-semibold"
                onClick={() => setAddScoreModal(false)}
              >
                Don't Save
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
