import React, { useEffect, useState } from 'react'
import { BsFillFileBarGraphFill } from "react-icons/bs";
import { ToastContainer } from "react-toastify";
import { Icon } from "@iconify/react";
import EditMatchDetailsModal from "./EditMatchDetailsModal";
import moment from "moment";
import { useParams } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import { APIurls } from '../../../api/apiConstant';
import EditThreeButton from "./EditThreeButton";
import EditQuarterFinalModal from './EditQuarterFinalModal.js'
import { useAuth } from '../../../store/AuthContext.js';
import AddScoreGameFixturesModal from './addScoreGameFixtureModel.jsx';

export default function FinalBracket() {

    const { tournamentId } = useParams();
    const { getAccessToken } = useAuth();

    const [matchDetailsModal, setMatchDetailsModal] = useState(false);
    const [addScoreModal, setAddScoreModal] = useState(false);
    const [editRoundsModal, setEditRoundsModal] = useState(false);
    const [roundID, setRoundID] = useState("");
    const [matchID, setMatchID] = useState("");
    const [announceStatus, setAnnounceStatus] = useState("");
    const [tournamentType, setTournamentType] = useState("");
    const [loading, setLoading] = useState(false);
    const [gameFixing, setGameFixing] = useState([]);
    const [isRefetch, setIsRefetch] = useState(true)

    const fetchFinalBracket = async () => {
        try {
            const token = await getAccessToken();
            setLoading(true);
            const response = await fetch(`${APIurls.fetchGameFixtures}/${tournamentId}?bracket=Final Bracket`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const result = await response.json();
            if (!response.ok) {
                console.log(result.message);
            }
            console.log("Game Fixings for Double_KnockOut Final Bracket", result?.response);
            setGameFixing(result?.response?.rounds);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }


    const fetchTournamentType = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchOverViewOfTournament}/${tournamentId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message);
            }
            console.log("Tournament Type", result?.response?.tournamentDetails);
            setTournamentType(result?.response?.tournamentDetails?.format);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchTournamentType();
    }, []);
    useEffect(() => {
        fetchFinalBracket();
        setIsRefetch(false)
    }, [isRefetch])

    const handleEditMatchDetailsModal = (matchId) => {
        setMatchID(matchId);
        setMatchDetailsModal(true);
    };

    const handleAddScoreModal = (matchId, status) => {
        setAddScoreModal(true);
        setMatchID(matchId);
        setAnnounceStatus(status);
    };

    const handleEditRoundModal = (id) => {
        setRoundID(id);
        setEditRoundsModal(true);
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-[50vh]">
                <RotatingLines
                    visible={true}
                    height="100"
                    width="100"
                    color="gray"
                    strokeColor="#FB6108"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        );
    }

    return (
        <div className="overflow-x-auto w-[100%]">
            <div>
                <div className="w-full flex items-center">
                    {gameFixing?.map((div, i) => (
                        <div key={i} className="min-w-[37%] flex flex-col gap-[2rem] py-5 px-5">
                            <div className="flex items-center justify-center gap-3">
                                <p className="font-semibold text-2xl text-center">{div?.roundName}</p>
                                <div onClick={() => handleEditRoundModal(div?.roundID)}>
                                    <div className="border p-1 bg-white text-black rounded-md cursor-pointer">
                                        <Icon icon="material-symbols:edit-rounded" className="text-[1.7rem]" />
                                    </div>
                                </div>

                            </div>
                            {div?.matches?.map((div2) => (
                                <div key={div2?.id} className="flex flex-col gap-[1.7rem] px-2 py-[1.5rem] w-[100%] bg-white shadow-lg rounded-md">
                                    <div className="w-[100%] flex items-center justify-between bg-[#FB6108] px-4 py-1 rounded-md">
                                        <p className="text-xl font-black text-white">{div2?.name}</p>
                                        <div className="flex items-center gap-2">
                                            <Icon
                                                icon="material-symbols:edit-rounded"
                                                className="text-[1.5rem] p-1 bg-white rounded-md cursor-pointer"
                                                onClick={() => handleEditMatchDetailsModal(div2?.id)}
                                            />
                                            <BsFillFileBarGraphFill
                                                title="Stats"
                                                className="text-[1.5rem] p-1 bg-white rounded-md cursor-pointer"
                                                onClick={() => handleAddScoreModal(div2?.id, div2?.status)}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-start flex-col">
                                        <div className="flex items-center gap-3 px-3">
                                            <div className="w-[100%] flex justify-between items-center rounded-md py-1 border border-[#808080]">
                                                <p
                                                    className="text-[#000019] text-base font-medium px-3 whitespace-nowrap overflow-hidden text-ellipsis flex-shrink"
                                                    title={`${div2?.participantA}`}
                                                >
                                                    {div2?.participantA}
                                                </p>
                                                <EditThreeButton className="text-[1rem] text-[#808080] mx-1 flex-grow-0 flex-shrink-0 cursor-pointer" />
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-3 mt-[10px] px-3">
                                            <div className="w-[100%] flex justify-between items-center rounded-md py-1 border border-[#808080]">
                                                <p
                                                    className="text-[#000019] text-base font-medium px-3 whitespace-nowrap overflow-hidden text-ellipsis flex-shrink"
                                                    title={`${div2?.participantB}`}
                                                >
                                                    {div2?.participantB}
                                                </p>
                                                <EditThreeButton className="text-[1rem] text-[#808080] mx-1 flex-grow-0 flex-shrink-0 cursor-pointer" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-between px-3 items-center">
                                        <p className="text-sm text-[#000019] font-medium whitespace-nowrap overflow-hidden text-ellipsis w-[50%]">
                                            {div2?.venue?.name ? div2?.venue?.name : "Club"}
                                            <br />
                                            {div2?.venue?.location?.City
                                                ? div2?.venue?.location?.City
                                                : "City"}
                                        </p>
                                        <p className="text-sm text-[#000019] font-medium">
                                            Date :
                                            {` ${div2.dateOfPlay !== null
                                                ? moment(div2.dateOfPlay).format("MMM Do YY")
                                                : moment().format("MMM Do YY")
                                                }`}
                                            <br />
                                            Time :
                                            {` ${div2.timing !== null
                                                ? moment(div2.timing).format("LT")
                                                : moment().format("LT")
                                                }`}
                                        </p>
                                    </div>
                                    {div2?.winner && (
                                        <div className="flex justify-center items-center mt-[-1rem]">
                                            <div className="w-[100%] bg-green-600 text-center text-white font-bold rounded-md py-1.5">
                                                WINNER - {div2?.winner?.toUpperCase()}{" "}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <ToastContainer />
                        </div>
                    ))}
                </div>
            </div>

            {editRoundsModal && (
                <EditQuarterFinalModal
                    roundsModal={setEditRoundsModal}
                    roundID={roundID}
                    setIsRefetch={setIsRefetch}
                />
            )}
            {matchDetailsModal && (
                <EditMatchDetailsModal
                    setMatchDetailsModal={setMatchDetailsModal}
                    matchID={matchID}
                    setIsRefetch={setIsRefetch}
                />
            )}
            {addScoreModal && (
                <AddScoreGameFixturesModal
                    setAddScoreModal={setAddScoreModal}
                    matchID={matchID}
                    status={announceStatus}
                    setIsRefetch={setIsRefetch}
                    tournamentType={tournamentType}
                />
            )}
        </div>
    )
}
