import React, { useState } from 'react'
import WinnersBracket from './WinnersBracket';
import LosersBracket from './LosersBracket';
import FinalBracket from './FinalBracket';

export default function DoubleKnockOutTabs() {

    const [tab, setTab] = useState('winner');

    return (
        <div className='flex flex-col gap-[1rem] w-[100%] items-center justify-center rounded-md bg-white'>
            <div className='flex gap-[3rem] px-[1rem] pt-[0.5rem]'>
                <div onClick={() => setTab("winner")}>
                    <h2 className={`${tab === "winner" ? "text-primary border-b-2 border-primary" : 'text-[#808080]'} text-[1.2rem] sm:text-[1.3rem] font-bold text-[#808080] cursor-pointer`}>
                        Winners Bracket
                    </h2>
                </div>
                <div onClick={() => setTab("loosers")}>
                    <h2 className={`${tab === "loosers" ? "text-primary border-b-2 border-primary" : 'text-[#808080]'} text-[1.2rem] sm:text-[1.3rem] font-bold text-[#808080] cursor-pointer`}>
                        Losers Bracket
                    </h2>
                </div>
                <div onClick={() => setTab("final")}>
                    <h2 className={`${tab === "final" ? "text-primary border-b-2 border-primary" : 'text-[#808080]'} text-[1.2rem] sm:text-[1.3rem] font-bold text-[#808080] cursor-pointer`}>
                        Final
                    </h2>
                </div>
            </div>
            {
                tab === "winner" && <WinnersBracket />
            }
            {
                tab === "loosers" && <LosersBracket/>
            }
            {
                tab === "final" && <FinalBracket/>
            }
        </div>
    )
}
