import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

export default function TournamentDetailsTabs() {

    return (
        <div className='flex flex-col bg-white'>
            <div className='flex gap-[3rem] border-b-2 border-[#989898] px-[1rem] pt-[0.5rem]'>
                <NavLink to={""} end>
                    {({ isActive }) => (
                        <h2 className={`max-lg:text-[16px] text-xl font-bold pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            Overview of the Tournament
                        </h2>
                    )}
                </NavLink>
                <NavLink to={"team_creation"}>
                    {({ isActive }) => (
                        <h2 className={`max-lg:text-[16px] text-xl  font-bold pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            Creation of Participants
                        </h2>
                    )}
                </NavLink>
                <NavLink to={"game_fixtures"}>
                    {({ isActive }) => (
                        <h2 className={`max-lg:text-[16px] text-xl  font-bold pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            Game Fixtures
                        </h2>
                    )}
                </NavLink>
                {/* <NavLink to={"stats"}>
                    {({ isActive }) => (
                        <h2 className={`max-lg:text-[16px] text-xl  font-bold pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            Stats
                        </h2>
                    )}
                </NavLink> */}
                <NavLink to={"rewards"}>
                    {({ isActive }) => (
                        <h2 className={`max-lg:text-[16px] text-xl  font-bold pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            Rewards
                        </h2>
                    )}
                </NavLink>
            </div>
            <Outlet />
        </div>
    )
}
