import React, { useEffect, useState } from "react";
import { Icon } from '@iconify/react';
import DataTable from "react-data-table-component";
import { NavLink, useOutletContext } from "react-router-dom";
import moment from "moment";
import { useAuth } from "../../store/AuthContext";
import { APIurls } from "../../api/apiConstant";
import { useQuery } from "react-query";
import { RxCross2 } from "react-icons/rx";
import ArchiveDeleteTournamentModal from "./ArchiveDeleteTournamentModal";
import { toast, ToastContainer } from "react-toastify";
import ArchiveDeleteRemarkModal from "./TournamentDetails/ArchiveDeleteRemarkModal";

export default function Archived() {

  const archived = "archived";
  const { getAccessToken, userRole } = useAuth();
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [tournamentId, setTournamentId] = useState("");
  const [deleteRemarkModal, setDeleteRemarkModal] = useState(false);
  const [deleteRemarkData, setDeleteRemarkData] = useState("")

  const [Club, mainCategorySport, subCategorySport, sport] = useOutletContext();

  const fetchArchivedTournaments = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAllTournamentsBasedOnStatus}/${archived}?clubOwnerID=${Club}&mainCategoryID=${mainCategorySport}&subCategoryID=${subCategorySport}&sportID=${sport}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message);
      }
      const result = await response.json();
      return result?.response?.tournaments;
    } catch (error) {
      console.log("Error while fetching archived tournaments", error.message)
    }
  };
  const { data: archivedTournaments, isLoading, refetch } = useQuery(["archivedTournamentsData", archived], fetchArchivedTournaments);
  console.log("Archived Tournaments Data", archivedTournaments);

  useEffect(() => {
    refetch();
  }, [Club, mainCategorySport, subCategorySport, sport])

  const handleRecoverTournament = async (id) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.recoverTournamentFromArchive}/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
      const result = await response.json();
      if (result.code > 201) {
        console.log("Failed to recover a tournament", result)
        toast.error(result.message)
        return;
      }
      console.log("Tournament Recovered Successfully", result?.message)
      refetch();
      toast.success("Tournament Recovered Successfully")
    } catch (error) {
      console.log("Error in Recovering tournament", error)
    }
  }

  const handleDeleteTournamentModal = (id) => {
    setTournamentId(id)
    setDeleteModal(true)
  }

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };
  useEffect(() => {
    let mapData = archivedTournaments?.map((item) => {
      return { ...item };
    });
    mapData = mapData?.filter((item) => {
      return (
        item?.tournamentID?.toLowerCase().includes(searchInput.toLocaleLowerCase()) ||
        item?.title?.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
      );
    });
    setFilteredData(mapData);
  }, [archivedTournaments, searchInput]);

  const handleDeleteRemarkModal = (data) => {
    setDeleteRemarkModal(true);
    setDeleteRemarkData(data);
  }

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Tournament ID",
      selector: (row) => <div>{row?.tournamentID}</div>,
      center: true,
      grow: 2.3,
    },
    {
      name: "Title",
      selector: (row) => <div>{row?.title}</div>,
      center: true,
      grow: 2.5,
    },
    {
      name: "Club Name",
      selector: (row) => (
        row?.clubNames?.map((item, index) => (
          <div key={index}>
            <p>{item}</p>
          </div>
        ))
      ),
      center: true,
      grow: 3.5,
    },
    {
      name: "No.of Venues",
      selector: (row) => <div>{row?.venue}</div>,
      center: true,
      grow: 2
    },
    {
      name: "Category",
      selector: (row) => (
        <div>
          <p>{row?.category?.mainCategory}</p>
          <p>{row?.category?.subCategory}</p>
          <p>{row?.category?.sportName}</p>
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Type of Game",
      selector: row => (
        <div>{row?.gameType}</div>
      ),
      center: true,
      grow: 2.5
    },
    {
      name: "Format",
      selector: (row) => <div>{row?.tournamentType}</div>,
      center: true,
      grow: 1.5,
    },
    {
      name: "Players",
      selector: (row) => <p>{row?.participants}</p>,
      center: true,
    },
    {
      name: "Duration",
      selector: (row) => (
        <div>
          <p>{row?.duration?.start === null ? "NA" : moment(row?.duration?.start).format("MMM Do YY")}</p>
          <p>{row?.duration?.end === null ? "NA" : moment(row?.duration?.end).format("MMM Do YY")}</p>
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Status",
      selector: row => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
          py-[0.4rem] w-[7rem] ${row?.tournamentStatus === "PENDING" ? "text-[#FFCE06] bg-[#F1ECE3]" : row?.tournamentStatus === "ACTIVE" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
          <span className={`${row?.tournamentStatus === "PENDING" ? "bg-[#FFCE06]" : row?.tournamentStatus === "ACTIVE" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2 className="text-[13px]">{row?.tournamentStatus}</h2>
        </div>
      ),
      grow: 3.2,
      center: true,
    },
    {
      name: "Deleted Remark",
      selector: row => (
        <div
          className="hover:text-primary cursor-pointer hover:border-b hover:border-primary"
          onClick={() => handleDeleteRemarkModal(row?.Remark)}
        >
          {row?.Remark?.length > 30 ? row?.Remark?.slice(0, 30) + "..." : row?.Remark}
        </div>
      ),
      center: true,
      grow: 2.2,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-[0.3rem]">
          <NavLink to={`/tournaments/tournamentDetails/${row?.tournamentID}`}>
            <div className="border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer">
              <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
            </div>
          </NavLink>
          <div
            className="border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer"
            onClick={() => handleRecoverTournament(row?.tournamentID)}
          >
            <Icon icon="ooui:restore" className="text-[1.2rem] text-black" />
          </div>
          {!userRole?.role?.staff?.type &&
            <div
              className="border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer"
              onClick={() => handleDeleteTournamentModal(row?.tournamentID)}
            >
              <Icon icon="material-symbols:delete-rounded" className="text-[1.5rem] text-black" />
            </div>
          }
        </div>
      ),
      center: true,
      grow: 3,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        // fontSize: "15px",
      },
    },
    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    // pagination: {
    //    style: {
    //       boxShadow: "10px 5px 5px #ddd",
    //       marginBottom: "5px",
    //    },
    // },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1200px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  return (
    <div className="p-6">
      <div className="flex justify-between">
        <p className="text-xl font-semibold">Archived Tournaments</p>
        <div className="flex gap-4">
          <div className="flex py-[0.4rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
            <input
              type="text"
              className="w-[16rem] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
              placeholder="Search by Tournament ID or Title"
              value={searchInput}
              onChange={handleSearch}
            />
            {searchInput !== "" ?
              <RxCross2
                className="text-[20px] text-white bg-primary cursor-pointer rounded-md"
                onClick={() => setSearchInput("")}
              />
              :
              <Icon
                icon="ri:search-2-fill"
                className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer"
              />
            }
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        customStyles={customStyles}
        pagination
        paginationPerPage={10}
        progressPending={isLoading}
      />
      <ToastContainer />
      {deleteModal && (
        <ArchiveDeleteTournamentModal
          setDeleteModal={setDeleteModal}
          tournamentId={tournamentId}
          refetch={refetch}
        />
      )}
      {deleteRemarkModal && (
        <ArchiveDeleteRemarkModal
          setDeleteRemarkModal={setDeleteRemarkModal}
          deleteRemarkData={deleteRemarkData}
        />
      )}
    </div>
  );
}