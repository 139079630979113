import React from 'react'
import DataTable from 'react-data-table-component'
import { Icon } from "@iconify/react";
import { AiOutlinePlus } from "react-icons/ai";

export default function Stats() {

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        color: "#4D4D4D",
        fontSize: "13px",
        // background: "#EAECF0"
      },
    },
    head: {
      style: {
        background: "#EAECF0",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto",
      },
    },
  };

  const ScoresColumn = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "80px"
    },
    {
      name: "Player",
      selector: row => (
        <div>Sai</div>
      )
    },
    {
      name: "Team",
      selector: row => (
        <div>Sky Goal Team</div>
      )
    },
    {
      name: "Goals",
      selector: row => (
        <div>9</div>
      )
    }
  ]

  const ScoresData = ["1", "2", "3", "4"]

  const assistColumns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "80px"
    },
    {
      name: "Player",
      selector: row => (
        <div>Ram</div>
      )
    },
    {
      name: "Team",
      selector: row => (
        <div>Pay Insta Team</div>
      )
    },
    {
      name: "Goals",
      selector: row => (
        <div>7</div>
      )
    }
  ]

  const assistData = ["1", "2", "3", "4"]

  const redCards_Columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "80px"
    },
    {
      name: "Player",
      selector: row => (
        <div>Shiva</div>
      )
    },
    {
      name: "Team",
      selector: row => (
        <div>Sky Goal Team</div>
      )
    },
    {
      name: "Goals",
      selector: row => (
        <div>5</div>
      )
    }
  ]

  const redCards_Data = ["1", 2, 4, "4"]

  const yellowCards_Columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "80px"
    },
    {
      name: "Player",
      selector: row => (
        <div>Ram</div>
      )
    },
    {
      name: "Team",
      selector: row => (
        <div>No Team</div>
      )
    },
    {
      name: "Goals",
      selector: row => (
        <div>3</div>
      )
    }
  ]

  const yellowCards_Data = ["2", "3", "4"]

  const teamsColumns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "80px"
    },
    {
      name: "Teams List",
      selector: row => (
        <div>Shiva</div>
      )
    },
    {
      name: "Scores",
      selector: row => (
        <div>0</div>
      )
    },
    {
      name: "Assists",
      selector: row => (
        <div>0</div>
      )
    },
    {
      name: "Red Cards",
      selector: row => (
        <div>2</div>
      )
    },
    {
      name: "Yellow Cards",
      selector: row => (
        <div>0</div>
      )
    },
    {
      name: "Result",
      selector: row => (
        <div>1</div>
      )
    }
  ]

  const teamsData = ["1",2,"3","4",5]

  return (
    <div>
      <div className='px-4 py-4'>
        <h3 className='text-xl font-semibold text-[#000000] pb-3'>Top Scores</h3>
        <DataTable
          columns={ScoresColumn}
          data={ScoresData}
          customStyles={customStyles}
          pagination
          paginationPerPage={10}
          selectableRows
        //   selectableRowsComponent={CustomCheckbox}
        />
      </div>
      <div className='px-4 py-4'>
        <h3 className='text-xl font-semibold text-[#000000] pb-3'>Top Assists</h3>
        <DataTable
          columns={assistColumns}
          data={assistData}
          customStyles={customStyles}
          pagination
          paginationPerPage={10}
          selectableRows
        //   selectableRowsComponent={CustomCheckbox}
        />
      </div>
      <div className='px-4 py-4'>
        <h3 className='text-xl font-semibold text-[#000000] pb-3'>Top Red Cards</h3>
        <DataTable
          columns={redCards_Columns}
          data={redCards_Data}
          customStyles={customStyles}
          pagination
          paginationPerPage={10}
          selectableRows
        //   selectableRowsComponent={CustomCheckbox}
        />
      </div>
      <div className='px-4 py-4'>
        <h3 className='text-xl font-semibold text-[#000000] pb-3'>Top Yellow Cards</h3>
        <DataTable
          columns={yellowCards_Columns}
          data={yellowCards_Data}
          customStyles={customStyles}
          pagination
          paginationPerPage={10}
          selectableRows
        //   selectableRowsComponent={CustomCheckbox}
        />
      </div>
      <div className='px-4 py-4'>
        <div className='flex justify-between items-center pb-2'>
          <h3 className='text-xl font-semibold text-[#000000]'>List of Teams</h3>
          <div className='flex gap-4'>
            <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
              <Icon
                icon='material-symbols:delete'
                className='text-[1.5rem]'
              />
              <span className='text-[1rem] font-medium'>Remove</span>
            </div>
            <div className='flex items-center text-white bg-primary p-2 rounded-md gap-1 cursor-pointer'>
              <button>Add Team</button>
              <AiOutlinePlus className="text-[1.2rem]" />
            </div>
          </div>
        </div>
        <DataTable
          columns={teamsColumns}
          data={teamsData}
          customStyles={customStyles}
          pagination
          paginationPerPage={10}
          selectableRows
        //   selectableRowsComponent={CustomCheckbox}
        />
      </div>
    </div>
  )
}
