import React from 'react'

export default function Rewards() {
  return (
    <div className='border border-gray-200 p-3 rounded-md'>
      <p className='text-2xl font-semibold px-4 py-2'>Rewards</p>
      <div className='flex gap-4'>
        <div className='flex flex-col gap-[1.5rem] items-baseline px-[2rem] py-[1rem]'>
          <h2 className='font-medium text-xl max-xl:text-lg'>Prize Money Information</h2>
          {/*Prize Type*/}
          <select
            className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
            size='lg'
          >
            <option>Cash Prize</option>
            <option>Certificates</option>
            <option>Trophies</option>
          </select>
          <input
            type="text"
            className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
            placeholder="Winner Prize Money"
          />
          <input
            type="text"
            className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
            placeholder="Runner-Up Prize Money"
          />
          <input
            type="text"
            className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
            placeholder="2nd Runner-Up Prize Money"
          />
          <input
            type="text"
            className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
            placeholder="MVP"
          />
        </div>
        <div className='flex flex-col gap-[1.5rem] px-[2rem] py-[1rem]'>
          <h2 className='font-medium text-xl max-xl:text-lg'>Trophy/Awards Information</h2>
          <div className='flex items-center justify-between'>
            <p>Winner</p>
            {/* <Switch color="purple"
              ripple={false}
              className="h-full w-full checked:bg-primary col-span-1"
              containerProps={{
                className: "w-11 h-6",
              }}
              circleProps={{
                className: "before:hidden left-0.5 border-none",
              }}
            /> */}
          </div>
          <div className='flex items-center justify-between'>
            <p>Runner-Up</p>
            {/* <Switch color="purple"
              ripple={false}
              className="h-full w-full checked:bg-primary col-span-1"
              containerProps={{
                className: "w-11 h-6",
              }}
              circleProps={{
                className: "before:hidden left-0.5 border-none",
              }}
            /> */}
          </div>
          <div className='flex items-center justify-between'>
            <p>2nd Runner-Up</p>
            {/* <Switch color="purple"
              ripple={false}
              className="h-full w-full checked:bg-primary col-span-1"
              containerProps={{
                className: "w-11 h-6",
              }}
              circleProps={{
                className: "before:hidden left-0.5 border-none",
              }}
            /> */}
          </div>
          <div className='flex items-center justify-between'>
            <p>MVP (All Sports)</p>
            {/* <Switch color="purple"
              ripple={false}
              className="h-full w-full checked:bg-primary col-span-1"
              containerProps={{
                className: "w-11 h-6",
              }}
              circleProps={{
                className: "before:hidden left-0.5 border-none",
              }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  )
}
