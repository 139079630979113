import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { toast } from "react-toastify";
import Select from "react-select";
import { useQuery } from "react-query";
import { useAuth } from "../../../store/AuthContext";
import { APIurls } from "../../../api/apiConstant";
import { PulseLoader } from "react-spinners";
import { RotatingLines } from "react-loader-spinner";

export default function EditVenueModal({
  setEditVenueModal,
  venueId,
  venueRefetch,
}) {
  const { getAccessToken } = useAuth();

  const [venue, setVenue] = useState({ value: "", label: "" });
  const [arena, setArena] = useState({ value: "", label: "" });
  const [court, setCourt] = useState({ value: "", label: "" });
  const [venuesOptions, setVenueOptions] = useState([]);
  const [arenaOptions, setArenaOptions] = useState([]);
  const [courtOptions, setCourtOptions] = useState([]);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialArena, setInitialArena] = useState(false);
  const [venueDataLoading, setVenueDataLoading] = useState(false);

  const fetchDropDownsData = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${APIurls.fetchDropDownDataForAddingVenues}?club=${venue?.value === undefined ? "" : venue?.value
        }&arena=${arena?.value === undefined ? "" : arena?.value}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message);
      }
      console.log("Drop Down Data", result?.response);
      setVenueOptions(
        result?.response?.clubs?.map((item) => ({
          value: item?.clubId,
          label: item?.clubName,
        }))
      );
      if (result?.response?.arenas?.length === 0) {
        setArenaOptions([]);
        setCourtOptions([]);
      } else {
        setArenaOptions(
          result?.response?.arenas?.map((item) => ({
            value: item?.arenaId,
            label: item?.arenaName,
          }))
        );
        setCourtOptions(
          result?.response?.court?.map((item) => ({
            value: item?.courtId,
            label: item?.courtName,
          }))
        );
      }
      return result?.response;
    } catch (error) {
      console.log("Error while fetching drop down data for adding venues", error);
    }
  };

  const { data: dropdownsData, refetch } = useQuery(
    "dropDownVenues",
    fetchDropDownsData
  );

  const fetchExistingVenueDetails = async () => {
    setVenueDataLoading(true);
    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${APIurls.fetchSpecificVenueDetails}/${venueId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message);
      }
      setVenue({
        value: result?.response?.venue?.clubID,
        label: result?.response?.venue?.clubName,
      });
      const venueDetails = result?.response?.venue;
      console.log("venueDetails : ", venueDetails);
      if (venueDetails?.ArenaID && venueDetails?.ArenaName) {
        setArena({
          value: venueDetails?.ArenaID,
          label: venueDetails?.ArenaName,
        });
      } else {
        setArena({ value: "", label: "" });
      }
      if (venueDetails?.boardID && venueDetails?.boardName) {
        setCourt({
          value: venueDetails?.boardID,
          label: venueDetails?.boardName,
        });
      } else {
        setCourt({ value: "", label: "" });
      }
      if (venueDetails?.arenasLength > 0) {
        const ArenaSports = venueDetails?.arenas?.map((arena) => ({
          value: arena?.id,
          label: arena?.name,
        }));
        setArenaOptions(ArenaSports);
      } else {
        setArenaOptions([]);
      }
      if (venueDetails?.boardsLength > 0) {
        const ArenaBoards = venueDetails?.boards?.map((board) => ({
          value: board?.id,
          label: board?.name,
        }));
        setCourtOptions(ArenaBoards);
      } else {
        setCourtOptions([]);
      }
      setInitialArena(true);
    } catch (error) {
      console.log("Error while fetching Existing Venue Details", error);
    }
    setVenueDataLoading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchExistingVenueDetails();
    })();
  }, []);

  const handleEditVenue = async (e) => {
    e.preventDefault();

    if (!venue?.value === "") {
      setShowError(true);
      return;
    }
    const updatedVenueDetails = {
      venueClubId: venue?.value,
      arenaId: arena?.value,
      boardId: court?.value,
    };
    try {
      setIsLoading(true);
      const token = await getAccessToken();
      const res = await fetch(`${APIurls.updateVenueDetails}/${venueId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedVenueDetails),
      });
      const result = await res.json();
      if (result?.code > 201) {
        toast.error("Venue Not Updated");
        throw new Error(result?.message);
      }
      console.log("Updated Venue Data", result?.response);
      setIsLoading(false);
      setEditVenueModal(false);
      venueRefetch();
      toast.success("Venue Updated Successfully");
    } catch (error) {
      console.error("Error in saving edited venue " + error?.message);
    }
  };

  useEffect(() => {
    if (!initialArena) {
      setArena({ value: "", label: "" });
      setCourt({ value: "", label: "" });
    }
    refetch();
  }, [venue, refetch, initialArena]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F6F7FB",
      width: "250px", // Fixed width for dropdowns
    }),
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
      <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[23%] relative">
        <div className="flex justify-between items-center">
          <h1 className="text-[20px] font-bold">Edit Venue</h1>
          <div
            onClick={() => setEditVenueModal(false)}
            className={
              "shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm cursor-pointer"
            }
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </div>
        </div>
        <div>
          {venueDataLoading ? (
            <div className="flex justify-center items-center">
              <RotatingLines
                visible={true}
                height="35"
                width="35"
                color="gray"
                strokeColor="#FB6108"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <form onSubmit={handleEditVenue}>
              <div className="flex flex-col justify-center items-center gap-[1.5rem] pb-[1rem]">
                <div className="">
                  <Select
                    className="w-[100%] text-gray-700"
                    placeholder="Select Venue"
                    value={venue?.value ? venue : ""}
                    options={venuesOptions}
                    styles={customStyles}
                    onChange={(selectedOption) => {
                      setVenue(selectedOption);
                      setInitialArena(false);
                    }}
                  />
                  {!venue?.value && showError && (
                    <p className="text-sm text-red-500">
                      *This field is required
                    </p>
                  )}
                </div>
                <Select
                  className="text-gray-700"
                  placeholder="Select Arena"
                  value={arena?.value ? arena : ""}
                  options={arenaOptions}
                  styles={customStyles}
                  onChange={(selectedOption) => setArena(selectedOption)}
                />
                <Select
                  className="text-gray-700"
                  placeholder="Select Court"
                  value={court?.value ? court : ""}
                  options={courtOptions}
                  styles={customStyles}
                  onChange={(selectedOption) => setCourt(selectedOption)}
                />
              </div>
              <div className="flex justify-end">
                {isLoading ? (
                  <PulseLoader color="orange" size={8} />
                ) : (
                  <button
                    type="submit"
                    className="w-[90px] font-semibold flex items-center text-white bg-primary px-4 py-2 cursor-pointer rounded-md gap-1"
                  >
                    Save
                    <PiGreaterThan />
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
