import React from 'react'
import { PiGreaterThan } from "react-icons/pi";
import { FaChevronDown } from "react-icons/fa6";
import { useNavigate, useParams } from 'react-router-dom';
import TournamentDetailsTabs from './TournamentDetailsTabs';

export default function TournamentDetails() {

    const navigate = useNavigate();
    const { tournamentId } = useParams();

    return (
        <>
            <div className='flex bg-white pt-[1rem] mt-3'>
                <div className='flex items-center gap-1 px-[1rem]'>
                    <p className='text-lg font-semibold text-[#808080] cursor-pointer' onClick={() => navigate("/tournaments")}>Tournament</p>
                    <PiGreaterThan />
                    <p className='text-base font-semibold text-[#808080]'>{tournamentId}</p>
                    <FaChevronDown className='text-lg font-semibold text-[#808080]' />
                </div>
            </div>
            <TournamentDetailsTabs />
        </>
    )
}