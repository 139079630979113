import React, { useState } from 'react'
import gamebeezClubLogo from '../../assets/clubPartnerAppIcon.svg'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom';
import authEmailLogo from '../../assets/auth-email-logo.svg';
import roundMailLogo from '../../assets/ic_round-mail.svg';
import backToLoginArrow from "../../assets/akar-icons_arrow-back-thick-fill.svg";
import { toast } from 'react-toastify';
import { useAuth } from '../../store/AuthContext';
import { handleFirebaseError } from '../../utils/FirebaseHandle';
import { ThreeDots } from 'react-loader-spinner';

export default function ForgotPassword() {

    const { register, handleSubmit, formState: { errors }, control, reset } = useForm();
    const navigate = useNavigate();
    const [loaderBtn, setLoaderBtn] = useState(false);
    const { resetPassword } = useAuth()

    const onSubmit = async (data) => {
        const email = data?.email
        setLoaderBtn(true)
        await resetPassword(email).then((e) => {
            setLoaderBtn(false)
            toast.success("Password Reset Mail sent successfully !")
            navigate("/login")

        }).catch(err => {
            setLoaderBtn(false)
            toast.error(handleFirebaseError(err))

        })
    }


    return (

        <div className="h-screen w-full flex items-center justify-center">
            {/* <div className="h-screen w-full flex items-center justify-center" style={{ backgroundImage: `url(${authBgImage})` }}> */}
            <div className="flex flex-col w-[32rem] bg-[#FFF] shadow-3xl px-16 py-20 rounded-lg">
                <div className="flex justify-start max-w-[100px]">
                    <img src={gamebeezClubLogo} alt="GamebeezClub-logo" className='rounded-2xl' />
                </div>
                <h1 className="text-[1.5rem] text-[#212529] font-bold mt-4">Forgot Password?</h1>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full flex flex-col gap-[1rem]"
                >
                    <div className="rounded-lg border-2 border-[#FB6108] p-4 mt-7">
                        <div className="flex mb-4">
                            <div className="flex justify-center items-center rounded-full p-4 bg-[#FFFFFFB2] shadow-3xl">
                                <img src={roundMailLogo} alt="" />
                            </div>

                            <div className="pl-4">
                                <p className="text-[#212529] text-base font-semibold">Reset via Email</p>
                                <p className="text-[#21252980] text-sm font-normal">We will send a link to reset your password</p>
                            </div>

                        </div>

                        <div className="group flex flex-col gap-[0.5rem]">
                            <div className={`flex flex-row text-[0.9rem] font-[600] group-focus-within:bg-white  group-focus-within:shadow-md border  ${!errors.email ? "border-[#AFBACA]" : "border-red-600 "
                                } items-center rounded-md px-[0.5rem] xs:px-[1rem] py-[0.5rem]`}>
                                <div className="">
                                    <img src={authEmailLogo} alt="" />
                                </div>

                                <input
                                    type="text"
                                    name="email"
                                    placeholder="example@gmail.com"
                                    className="ml-[1rem] outline-none w-1 border-none flex-grow placeholder:text-[#858585]"
                                    {...register("email", {
                                        required: "*This field is required.",
                                        pattern: /^\S+@\S+$/i,
                                    })}
                                />
                            </div>
                            {errors.email?.type === "required" && (
                                <p className="text-red-600 text-sm">{errors.email.message}</p>
                            )}
                            {errors.email?.type === "pattern" && (
                                <p className="text-sm text-red-600">Invalid email</p>
                            )}

                        </div>
                    </div>
                    <div className="flex flex-row justify-center">
                        {loaderBtn ? (
                            <ThreeDots
                                height="50"
                                width="50"
                                radius="9"
                                color="#FB6108"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        ) :
                            (<button
                                type="submit"
                                className="w-full text-white text-[1.1rem] rounded-md px-[1.5rem] py-[0.8rem] bg-[#FB6108] font-bold"
                            >
                                Login
                            </button>)}
                    </div>
                </form>

                <NavLink to={'/'}>
                    <div className="flex mt-3">
                        <img src={backToLoginArrow} alt="" />
                        <p className="text-[#C4C4C4] text-sm font-medium cursor-pointer hover:text-[#FB6108]"
                        >Back to Login</p>
                    </div>
                </NavLink>

            </div>
        </div>


    )
}
