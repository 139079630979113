import React from 'react'
import { NavLink } from 'react-router-dom'

export default function TournamentCategories() {
    return (
        <div className='flex flex-col gap-[1rem] rounded-md bg-white'>
            <div className='flex gap-[3rem] border-b-2 border-[#989898] pt-[1rem]'>
                <NavLink to={""} end>
                    {({ isActive }) => (
                        <h2 className={`text-xl max-lg:text-[17px] font-bold pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            All
                        </h2>
                    )}
                </NavLink>
                <NavLink to={"ongoing"} end>
                    {({ isActive }) => (
                        <h2 className={`text-xl max-lg:text-[17px] font-bold pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            Ongoing
                        </h2>
                    )}
                </NavLink>
                <NavLink to={"upcoming"}>
                    {({ isActive }) => (
                        <h2 className={`text-xl max-lg:text-[17px] font-bold pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            Upcoming
                        </h2>
                    )}
                </NavLink>
                <NavLink to={"completed"}>
                    {({ isActive }) => (
                        <h2 className={`text-xl max-lg:text-[17px] font-bold pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            Completed
                        </h2>
                    )}
                </NavLink>
                <NavLink to={"archived"}>
                    {({ isActive }) => (
                        <h2 className={`text-xl max-lg:text-[17px] font-bold pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            Archived
                        </h2>
                    )}
                </NavLink>
            </div>
        </div>
    )
}
