import React, { useState } from 'react'
import { PiGreaterThan } from "react-icons/pi";
import uploadImage from '../../../assets/uploadImg.svg'
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate, useParams } from 'react-router-dom';
import { MdCloudUpload } from 'react-icons/md';
import { RxCross2 } from "react-icons/rx";
import { toast, ToastContainer } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import Select from 'react-select'
import { useForm } from 'react-hook-form';

export default function AddOrganizerOrSponsor() {

    const [accType, setAccType] = useState({ value: "", label: "" })
    const [markDefaultSponsor, setMarkDefaultSponsor] = useState(false)
    const [previewImage, setPreviewImage] = useState("")
    const [selectedFile, setSelectedFile] = useState({})
    const [showError, setShowError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm();

    const navigate = useNavigate();
    const { tournamentId } = useParams();
    const { getAccessToken } = useAuth();

    const handleFileInput = (event) => {
        let file = event.target.files[0];
        setSelectedFile(file)
        setPreviewImage(URL.createObjectURL(file))
    };

    const triggerFileInput = () => {
        document.getElementById("fileInput").click();
    };

    const handleAddOrganizer = async (data) => {

        console.log("Data-->", data)

        if (!accType?.value) {
            setShowError(true);
            return;
        }

        const { organizerName, bankName, accountNumber, accHolderName, ifscCode } = data

        const body = {
            name: organizerName,
            defaultSponsor: markDefaultSponsor === true ? markDefaultSponsor : false,
            SponsorImg: selectedFile,
            accountNumber: accountNumber,
            accountHolderName: accHolderName,
            bankName: bankName,
            IFSC_Code: ifscCode?.toUpperCase(),
            accountType: accType?.value,
        }
        const formData = new FormData();
        for (let key in body) {
            formData.append(`${key}`, body[key]);
        }
        try {
            setIsLoading(true)
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.addOrganizer}/${tournamentId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData
            })
            const result = await response.json();
            if (result.code > 201) {
                toast.error(result.message);
                throw new Error(result?.message);
            }
            console.log("Sponsor Added Successfully", result?.response);
            toast.success("Sponsor Added Successfully");
            setTimeout(() => { navigate(`/tournaments/tournamentDetails/${tournamentId}`) }, 2000)
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false)
    }

    const accountTypeOptions = [
        { value: "savings", label: "Savings" },
        { value: "current", label: "Current" }
    ]

    return (
        <div className='pt-3 px-3 pb-8 my-[1rem] bg-white'>
            <form onSubmit={handleSubmit(handleAddOrganizer)}>
                <div className='flex justify-between items-center px-4 py-2'>
                    <div className='flex items-center gap-2 max-lg:gap-1'>
                        <p
                            className='text-[#808080] font-semibold text-base cursor-pointer max-lg:text-[14px]'
                            onClick={() => navigate("/tournaments")}
                        >
                            Tournament
                        </p>
                        <PiGreaterThan className='text-[#808080] font-semibold' />
                        <p
                            className='text-[#808080] font-semibold text-base cursor-pointer max-lg:text-[14px]'
                            onClick={() => navigate(`/tournaments/tournamentDetails/${tournamentId}`)}
                        >
                            OverView of Tournament
                        </p>
                        <PiGreaterThan className='text-[#808080] font-semibold' />
                        <p className='text-xl font-semibold max-lg:text-[16px]'>Add Organizer/Sponsor</p>
                    </div>
                </div>
                {/* {organizers?.map((item, index) => { */}
                <div className='flex justify-center gap-[3rem] px-[2rem] py-[2rem]'>
                    <div className='w-full flex flex-col gap-[1.2rem]'>
                        <p className='text-xl font-semibold'>Organizer/Sponsor</p>
                        <div>
                            <input
                                type='text'
                                placeholder='Name'
                                className="w-full text-[0.75rem] mt-2 sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                                // value={organizerName}
                                // onChange={(e) => setOrganizerName(e.target.value)}
                                {...register("organizerName", {
                                    required: "*This field is required"
                                })}
                            />
                            {/* {!organizerName && showError && (
                                <p className='text-xs text-red-400'>*This field is required</p>
                            )} */}
                            {errors?.organizerName && (
                                <p className='text-xs text-red-400'>{errors?.organizerName?.message}</p>
                            )}
                        </div>
                        <div>
                            <input
                                type='text'
                                placeholder='Account Number'
                                className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                                // value={accNumber}
                                // onChange={(e) => setAccNumber(e.target.value)}
                                {...register("accountNumber", {
                                    required: "*This field is required"
                                })}
                            />
                            {/* {!accNumber && showError && (
                                <p className='text-xs text-red-400'>*This field is required</p>
                            )} */}
                            {errors?.accountNumber && (
                                <p className='text-xs text-red-400'>{errors?.accountNumber?.message}</p>
                            )}
                        </div>
                        <div>
                            <input
                                type='text'
                                placeholder='Account Holder Name'
                                className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                                // value={accHolderName}
                                // onChange={(e) => setAccHolderName(e.target.value)}
                                {...register("accHolderName", {
                                    required: "*This field is required"
                                })}
                            />
                            {/* {!accHolderName && showError && (
                                <p className='text-xs text-red-400'>*This field is required</p>
                            )} */}
                            {errors?.accHolderName && (
                                <p className='text-xs text-red-400'>{errors?.accHolderName?.message}</p>
                            )}
                        </div>
                        <div className="flex flex-col w-[100%] gap-[1.5rem] xl:hidden">
                            <div className="flex flex-col h-[225px] items-center justify-center bg-[#F4F5FA]">
                                <div className="w-[100%] flex justify-center">
                                    {previewImage !== "" ?
                                        <div className='flex flex-col items-center justify-center'>
                                            <img src={previewImage} className='w-[70%] pb-4' />
                                            <RxCross2
                                                className='text-[1.5rem] border border-gray-300 rounded-md text-white bg-red-400 cursor-pointer'
                                                onClick={() => setPreviewImage("")}
                                            />
                                        </div>
                                        :
                                        <div>
                                            <div className="flex justify-center items-center">
                                                <img src={uploadImage} className="w-10 " alt="upload img" />
                                            </div>
                                            <div
                                                className="text-primary flex items-center gap-3 cursor-pointer justify-center"
                                                onClick={triggerFileInput}
                                            >
                                                <MdCloudUpload className="w-[2rem] h-[2rem]" />
                                                <p className='max-lg:text-[14px]'>Upload Banner</p>
                                            </div>
                                            <p className="text-[#808080] text-sm text-center">
                                                Upload an image of Organizer/Sponsor
                                            </p>
                                            <p className="text-[0.8rem] text-[#808080] text-center">
                                                File Format <span className="text-black"> jpeg, png </span>
                                                <br />
                                                Recommended Size{" "}
                                                <span className="text-black"> 600x600 (1:1) </span>
                                            </p>
                                            <input
                                                type="file"
                                                id="fileInput"
                                                alt="uploaded image"
                                                className="hidden"
                                                onChange={handleFileInput}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col w-full gap-[1.2rem]'>
                        <div className='w-[120px] flex justify-center items-center rounded-md border border-[#D9D9D9] gap-1 px-2 py-2'>
                            <input
                                type='checkbox'
                                name="defaultSponsor"
                                className='accent-primary'
                                value={markDefaultSponsor}
                                onChange={(e) => setMarkDefaultSponsor(e.target.checked)} />
                            <p className='text-[14px] text-[#858585]'>Mark Default</p>
                        </div>
                        <div>
                            <input
                                type='text'
                                placeholder='Bank Name'
                                className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                                // value={bankName}
                                // onChange={(e) => setBankName(e.target.value)}
                                {...register("bankName", {
                                    required: "*This field is required"
                                })}
                            />
                            {/* {!bankName && showError && (
                                <p className='text-xs text-red-400'>*This field is required</p>
                            )} */}
                            {errors?.bankName && (
                                <p className='text-xs text-red-400'>{errors?.bankName?.message}</p>
                            )}
                        </div>
                        <div>
                            <input
                                type='text'
                                placeholder='IFSC Code'
                                className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                                // value={ifscCode}
                                // onChange={(e) => setIfscCode(e.target.value)}
                                {...register("ifscCode", {
                                    required: "*This field is required"
                                })}
                            />
                            {errors?.ifscCode && (
                                <p className='text-xs text-red-400'>{errors?.ifscCode?.message}</p>
                            )}
                        </div>
                        <div>
                            <Select
                                placeholder="Select Account Type"
                                className="w-full text-[#7F7F7F] font-[500] placeholder:text-[#7F7F7F] rounded-md outline-none"
                                value={accType?.value ? accType : ""}
                                options={accountTypeOptions}
                                onChange={(selectedOption) => setAccType(selectedOption)}
                            >
                            </Select>
                            {!accType?.value && showError && (
                                <p className='text-xs text-red-400'>*This field is required</p>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col w-[100%] gap-[1.5rem] max-xl:hidden">
                        <div className="flex flex-col h-[225px] items-center justify-center bg-[#F4F5FA]">
                            <div className="w-[100%] flex justify-center">
                                {previewImage !== "" ?
                                    <div className='flex flex-col items-center justify-center'>
                                        <img src={previewImage} className='w-[70%] pt-4' />
                                        <RxCross2
                                            className='text-[1.5rem] border border-gray-300 rounded-md text-white bg-red-400 cursor-pointer'
                                            onClick={() => setPreviewImage("")}
                                        />
                                    </div>
                                    :
                                    <div>
                                        <div className="flex justify-center items-center">
                                            <img src={uploadImage} className="w-10 " alt="upload img" />
                                        </div>
                                        <div
                                            className="text-primary flex items-center gap-3 cursor-pointer justify-center"
                                            onClick={triggerFileInput}
                                        >
                                            <MdCloudUpload className="w-[2rem] h-[2rem]" />
                                            <p>Upload Banner</p>
                                        </div>
                                        <p className="text-[#808080] text-sm">
                                            Upload an image of Organizer/Sponsor
                                        </p>
                                        <p className="text-[0.8rem] text-[#808080] text-center">
                                            File Format <span className="text-black"> jpeg, png </span>
                                            <br />
                                            Recommended Size{" "}
                                            <span className="text-black"> 600x600 (1:1) </span>
                                        </p>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            alt="uploaded image"
                                            className="hidden"
                                            onChange={handleFileInput}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='px-8 pb-4 text-[#db3445] font-semibold '>
                    <p>Note : *Make sure these details only belong to one person</p>
                </div>
                {/* })} */}
                <div className='px-8'>
                    {isLoading ?
                        <PulseLoader size={8} color='orange' />
                        :
                        <button
                            type='submit'
                            className='flex items-center justify-center text-white px-4 py-2 bg-primary rounded-md'
                        >
                            <AiOutlinePlus className='text-[1rem]' />
                            Add Organizer/Sponsor
                        </button>
                    }
                </div>
            </form>
            <ToastContainer />
        </div>
    )
}