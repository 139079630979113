import React from 'react';
import gamebeezClubLogo from '../assets/clubPartnerAppIcon.svg'
import { useAuth } from "../store/AuthContext";

function Navbar() {

  // const [optionType,setOptionType] = useState({})
  const { logout } = useAuth();

  // const options = [
  //   { value: "profile", label: "Profile"},
  //   { value: "logout", label: "Logout"}
  // ]

  // useEffect(()=>{
  //   if(optionType?.value === "logout"){
  //     logout();
  //   }
  // },[optionType])

  return (
    <>
      <nav className="bg-white flex justify-end px-[2rem]">
        <div className="w-[100%] flex justify-between items-center">
          <div className="w-[85px] h-[85px] flex justify-center items-center">
            <img src={gamebeezClubLogo} className="rounded-2xl w-[60px] h-[60px]" />
          </div>
          <div className="flex items-center gap-3">
            {/* <Select
              options={options}
              placeholder = "Gamebeez Club Owner"
              className="w-[250px]"
              onChange={(value)=>setOptionType(value)}
            >

            </Select> */}
            <button
              className="text-white px-4 py-2 bg-primary rounded-md"
              type="submit"
              onClick={() => logout()}
            >
              Logout
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
