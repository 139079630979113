import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import { PulseLoader } from 'react-spinners';
import { useAuth } from "../../../store/AuthContext.js";
import { APIurls } from "../../../api/apiConstant.js";

export default function OverViewEditPlayerModal({ setEditPlayerModal, refetch, participantId, selectionType }) {

    const [mobileNumber, setMobileNumber] = useState("")
    const [email, setEmail] = useState("")
    const [playerName, setPlayerName] = useState("")
    const [fetchError, setFetchError] = useState(false)
    const [showError, setShowError] = useState(false);
    const [loader, setLoader] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [editable, setEditable] = useState(false)

    const { getAccessToken } = useAuth();

    const handleFetchPlayerDetailsByNumber = async (e) => {
        e.preventDefault();
        if (!mobileNumber) {
            setFetchError(true);
            setShowError(false);
            return;
        }
        const mobileNoRegex = /^\+91\d{10}$/;
        if (!mobileNoRegex.test(mobileNumber)) {
            toast.error("Enter a Valid mobile Number ");
            return;
        }
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchPlayerDetailsByPhoneNumber}/${mobileNumber}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                console.error(result.message)
                toast.error(result.message)
                return;
            }
            toast.success("Player Details Fetched Successfully");
            console.log("Player Details fetched by Number", result?.response)
            setPlayerName(result?.response?.OfflineUSer_data?.FirstName + " " + result?.response?.OfflineUSer_data?.LastName);
            setEmail(result?.response?.OfflineUSer_data?.email)
            return;
        } catch (error) {
            console.log("Error while fetching player details using number", error)
        }
    }

    const fetchPlayerDetails = async () => {
        try {
            setIsLoading(true);
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchExistingTeamDetails}/${participantId}/${selectionType}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message)
            }
            setMobileNumber(result?.response?.participants?.phone)
            setPlayerName(result?.response?.participants?.name)
            setEmail(result?.response?.participants?.email)
            console.log("Specific Player Details-->", result?.response?.participants?.userMongoID)
            if (result?.response?.participants?.userMongoID === null) {
                setEditable(true)
            }
            // return result?.response?.participants
        } catch (error) {
            console.log("Error while fetching player details", error?.message);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchPlayerDetails();
    }, [])

    const handleSavePlayer = async (e) => {
        e.preventDefault();
        if ((mobileNumber === "") || !(email) || !(playerName)) {
            setShowError(true)
            return;
        }

        const participantDetails = {
            mobileNumber: mobileNumber,
            playerName: playerName,
            email: email
        }

        try {
            setLoader(true)
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.saveIndividualPlayerDetails}/${participantId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(participantDetails)
            })
            const result = await response.json();
            if (!response.ok) {
                toast.error(result.message)
                throw new Error(result.message)
            }
            console.log("Player Details Updated Successfully", result)
            refetch();
            toast.success("Player Details Updated Successfully")
            //   setEditPlayerModal(false)
        }
        catch (error) {
            console.log(error)
        }
        setLoader(false)
        setMobileNumber("");
        setEmail("");
        setPlayerName("");
    }

    const formatMobileNumber = (number) => {
        number = number?.replace(/[^0-9+]/g, "");
        console.log("number : ", number);
        if (number?.startsWith("+91")) {
            setMobileNumber(number);
        } else {
            if (number?.includes("+")) {
                setMobileNumber(`+91`);
            } else {
                setMobileNumber(`+91` + number);
            }
        }
    };

    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
            <div className="flex flex-col items-center gap-4 bg-white rounded-sm px-6 py-8 w-[35%] relative">
                {isLoading ? <div className="flex justify-center items-center">
                    <RotatingLines
                        visible={true}
                        height="35"
                        width="35"
                        color="gray"
                        strokeColor="#FB6108"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                    :
                    <div>
                        <div className="flex justify-between items-center">
                            <h1 className="text-[20px] font-bold">Edit Player</h1>
                            <div
                                onClick={() => setEditPlayerModal(false)}
                                className="cursor-pointer shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"
                                size="sm"
                            >
                                <Icon icon="material-symbols:close" className="text-[1.5rem]" />
                            </div>
                        </div>
                        <form onSubmit={handleSavePlayer}>
                            <div className="flex gap-[2rem] pt-[2rem] pb-[1rem] max-lg:flex max-lg:flex-col max-lg:gap-[1rem]">
                                <div className="flex flex-col gap-[1.5rem]">
                                    <div className="flex flex-col gap-1">
                                        <div className="flex items-center bg-[#EFF1F9]">
                                            <input
                                                className={`${editable ? "" : "cursor-not-allowed"} w-full font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none`}
                                                type="text"
                                                placeholder="Mobile Number"
                                                value={mobileNumber}
                                                onChange={(e) => formatMobileNumber(e.target.value)}
                                                maxLength={13}
                                                disabled={editable ? false : true}
                                            />
                                            {editable &&
                                                <button className="text-sm px-2 py-1 text-primary border-2 border-primary rounded-md mr-1"
                                                    type="submit"
                                                    onClick={handleFetchPlayerDetailsByNumber}
                                                >
                                                    Fetch
                                                </button>
                                            }
                                        </div>
                                        {
                                            !mobileNumber && fetchError && (
                                                <p className="text-sm text-red-600">*Please Enter Mobile Number</p>
                                            )}
                                        {!mobileNumber && showError && (
                                            <p className="text-red-600 text-sm">*This field is required</p>
                                        )}
                                    </div>
                                    <div>
                                        <input
                                            className={`${editable ? "" : "cursor-not-allowed"} w-full font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none`}
                                            type="text"
                                            placeholder="Email Address"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            disabled={editable ? false : true}
                                        />
                                        {!email && showError && (
                                            <p className="text-red-600 text-sm">*This field is required</p>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-[1.5rem]">
                                    <input
                                        className="w-[100%] font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none"
                                        type="text"
                                        value={playerName}
                                        placeholder="Player Name"
                                        onChange={(e) => setPlayerName(e.target.value)}
                                    />
                                    {!playerName && showError && (
                                        <p className="text-red-600 text-sm">*This field is required</p>
                                    )}
                                </div>
                            </div>
                            <div className="">
                                {loader ? <PulseLoader color="orange" size={8} />
                                    :

                                    <button
                                        type="submit"
                                        className="font-semibold w-[100px] flex items-center justify-center text-white bg-[#FB6108] px-2 py-2 cursor-pointer rounded-md gap-1"
                                    >
                                        Save
                                        <PiGreaterThan />
                                    </button>
                                }
                            </div>
                        </form>
                    </div>
                }
                {/* )
        } */}
            </div>
        </div>
    );
}
