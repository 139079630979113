import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { toast } from "react-toastify";
import { PulseLoader } from 'react-spinners';
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";

export default function AddParticipantModal({ setParticipantModal, teamID, refetch }) {

  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [playerName, setPlayerName] = useState("");
  const [fetchError, setFetchError] = useState(false)
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(false);

  const { getAccessToken } = useAuth();

  const handleFetchPlayerDetailsByNumber = async (e) => {
    e.preventDefault();
    if (mobileNumber === "") {
      setFetchError(true)
      return;
    }
    setFetchError(false)
    const token = await getAccessToken();
    try {
      const response = await fetch(`${APIurls.fetchPlayerDetailsByPhoneNumber}/${mobileNumber}`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (!response.ok) {
        toast.error(result?.response?.message);
        throw new Error(result.message)
      }
      setPlayerName(result?.response?.OfflineUSer_data?.FirstName + " " + result?.response?.OfflineUSer_data?.LastName)
      setEmail(result?.response?.OfflineUSer_data?.email)
      console.log("Players Details", result?.response)
      toast.success('Player Details fetched Successfully')

    }
    catch (error) {
      console.log(error)
    }
  }

  const handleAddPlayer = async (e) => {
    e.preventDefault()
    if ((mobileNumber === "") || !(email) || !(playerName)) {
      setShowError(true)
      return
    }

    const mobileNoRegex = /^\+91\d{10}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!mobileNoRegex.test(mobileNumber)) {
      toast.error("Enter a Valid mobile number");
      return;
    }
    else if (!emailRegex.test(email)) {
      toast.error("Enter a Valid Email Address");
      return;
    }

    const participantDetails = {
      mobileNo: mobileNumber,
      playerName: playerName,
      email: email
    }

    try {
      setLoader(true)
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.addPlayerIntoTeam}/${teamID}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(participantDetails)
      })
      const result = await response.json();
      if (!response.ok) {
        toast.error(result.message)
        throw new Error(result.message)
      }
      console.log("Participant Added Successfully", result)
      refetch();
      toast.success("Participant Added Successfully")
      // setParticipantModal(false)
    }
    catch (error) {
      console.log(error)
    }
    setLoader(false)
    setMobileNumber("");
    setEmail("");
    setPlayerName("");
  }

  const formatMobileNumber = (number) => {
    number = number?.replace(/[^0-9+]/g, "");
    console.log("number : ", number);
    if (number?.startsWith("+91")) {
      setMobileNumber(number);
    } else {
      if (number?.includes("+")) {
        setMobileNumber(`+91`);
      } else {
        setMobileNumber(`+91` + number);
      }
    }
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
      <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[40%] relative">
        {/* //   <div className="flex justify-center items-center">
        //     <RotatingLines
        //       visible={true}
        //       height="30"
        //       width="30"
        //       // color="white"
        //       strokeColor="#FB6108"
        //       strokeWidth="5"
        //       animationDuration="0.75"
        //       ariaLabel="rotating-lines-loading"
        //       wrapperStyle={{}}
        //       wrapperClass=""
        //     />
        //   </div> */}
        <div>
          <div className="flex justify-between items-center">
            <h1 className="text-[20px] font-bold">Add New Player</h1>
            <div
              onClick={() => setParticipantModal(false)}
              className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm cursor-pointer"
            >
              <Icon icon="material-symbols:close" className="text-[1.5rem]" />
            </div>
          </div>
          <form>
            <div className="flex gap-[2rem] py-[2rem] max-lg:flex max-lg:flex-col max-lg:gap-[1rem]">
              <div className="flex flex-col gap-[1.5rem]">
                <div>
                  <div className="flex items-center bg-[#EFF1F9]">
                    <input
                      className="w-[100%] text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none"
                      type="text"
                      placeholder="Mobile Number"
                      value={mobileNumber}
                      onChange={(e) => formatMobileNumber(e.target.value)}
                      maxLength={13}
                    />
                    <button className="text-sm px-2 py-1 text-primary border-2 border-primary rounded-md mr-1"
                      type="submit"
                      onClick={handleFetchPlayerDetailsByNumber}
                    >
                      Fetch
                    </button>
                  </div>
                  {
                    !mobileNumber && fetchError && (
                      <p className="text-sm text-red-600">*Please Enter Mobile Number</p>
                    )}
                  {!mobileNumber && showError && (
                    <p className="text-red-600 text-sm">*This field is required</p>
                  )}
                </div>
                <div>
                  <input
                    className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none"
                    type="text"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {!email && showError && (
                    <p className="text-red-600 text-sm">*This field is required</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col">
                <input
                  className="w-[100%] text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none"
                  type="text"
                  value={playerName}
                  placeholder="Player Name"
                  onChange={(e) => setPlayerName(e.target.value)}
                />
                {!playerName && showError && (
                  <p className="text-red-600 text-sm">*This field is required</p>
                )}
              </div>
            </div>
            <div className="">
              {loader ? <PulseLoader color="orange" size={8} />
                :
                <button onClick={handleAddPlayer}
                  type="submit"
                  className="w-[140px] flex items-center justify-center text-white bg-[#FB6108] px-4 py-2 cursor-pointer rounded-md gap-1 font-semibold"
                >
                  Add Player
                  <PiGreaterThan />
                </button>
              }
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
