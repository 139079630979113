import { RouterProvider } from 'react-router-dom';
import './App.css';
import router from './routes/Routes';
import { AuthContextProvider } from './store/AuthContext';
import { QueryClient, QueryClientProvider } from 'react-query';

function App() {

  const queryClient = new QueryClient();

  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <div className='font-exo'>
          <RouterProvider router={router} />
        </div>
      </QueryClientProvider>
    </AuthContextProvider>
  );
}

export default App;
