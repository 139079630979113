import React, { useState, useEffect } from 'react'
import { FaGreaterThan } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../store/AuthContext';
import { APIurls } from '../../api/apiConstant';
import { toast, ToastContainer } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import Select from 'react-select'
import { RotatingLines } from 'react-loader-spinner';

export default function CreateTournament() {

  const [showError, setShowError] = useState(false);
  const [mainCategoryData, setMainCategoryData] = useState([]);
  const [mainCategorySport, setMainCategorySport] = useState({ value: "", label: "" });
  const [selectType, setSelectType] = useState({ value: "", label: "" });
  const [selectFormat, setSelectFormat] = useState({ value: "", label: "" });
  const [fixingType, setFixingType] = useState({ value: "", label: "" });
  const [subCategorySport, setSubCategorySport] = useState({ value: "", label: "" });
  const [participants, setPartcipants] = useState("");
  const [selectSport, setSelectSport] = useState({ value: "", label: "" });
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sportsData, setSportsData] = useState([]);
  const [club, setClub] = useState([]);
  const [clubOptions, setClubOptions] = useState([]);
  const [loader, setLoader] = useState(false);

  const { getAccessToken, userRole } = useAuth();
  const navigate = useNavigate();

  const fetchMainCategories = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchCategories}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message)
      }
      console.log("Categories--->", result?.response);
      const data = result?.response?.categories
      setMainCategoryData(data);
    }
    catch (error) {
      console.log("Error while fetching Categories", error)
    }
  }

  const fetchAllSports = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAllTournamentSports}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message)
      }
      // console.log("Sports---------->", result.response?.tournamentSports)
      const data = result?.response?.tournamentSports
      setSportsData(data);
    }
    catch (error) {
      console.log("Something went wrong while fetching sports", error)
    }
  }

  const fetchClubs = async () => {
    try {
      setLoader(true);
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAllClubs}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message)
      }
      console.log("Clubs--->", result?.response?.clubs);
      const data = result?.response?.clubs?.map((div) => {
        return { value: div?.id, label: div?.clubName }
      })
      setClubOptions(data);
      if (userRole?.role?.staff?.type === true) {
        setClub(result?.response?.clubs?.map((item) => ({
          value: item?.id,
          label: item?.clubName
        })))
      }
    } catch (error) {
      console.log("Error while fetching clubs", error)
    }
    setLoader(false);
  }

  useEffect(() => {
    fetchMainCategories();
    fetchAllSports();
    fetchClubs();
  }, [])

  const handleCreateTournament = async (e) => {

    e.preventDefault();

    if ((club?.length === 0) || !mainCategorySport?.value || !selectType?.value || !selectFormat?.value || !fixingType?.value || !subCategorySport?.value || !participants || !selectSport?.value) {
      setShowError(true);
      return;
    }

    const body = {
      clubIds: club?.map((div) => div?.value),
      mainCategoryID: mainCategorySport?.value,
      subCategoryID: subCategorySport?.value,
      gameType: selectType?.value,
      formatType: selectFormat?.value,
      fixingType: fixingType?.value,
      sportID: selectSport?.value,
      participants: participants,
    }
    try {
      setIsLoading(true);
      const token = await getAccessToken();
      const res = await fetch(`${APIurls.tournamentCreation}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      })
      const data = await res.json();
      if (!res.ok) {
        console.log('Error occurs while creating tournament,', data?.message)
        toast.error(data.message);
        setIsLoading(false);
        return
      }
      console.log('Tournament Created Sucessfully', data)
      toast.success("Tournament Created Sucessfully");
      setTimeout(() => navigate('/tournaments/upcoming'), 2000);
    } catch (error) {
      console.log(error?.message)
    }
    setIsLoading(false);
    setClub("");
    setMainCategorySport({ value: "", label: "" });
    setSubCategorySport({ value: "", label: "" });
    setSelectType({ value: "", label: "" });
    setSelectFormat({ value: "", label: "" });
    setFixingType({ value: "", label: "" });
    setSelectSport({ value: "", label: "" });
    setPartcipants("");
  }

  const mainCategoryOptions = mainCategoryData?.map(item => ({
    value: item?._id,
    label: item?.categoryName
  }))

  useEffect(() => {
    if (mainCategorySport) {
      const mainCat = mainCategoryData?.find((div) => div?._id === mainCategorySport?.value);
      if (Array?.isArray(mainCat?.subCategoriesDetails)) {
        const data = mainCat?.subCategoriesDetails?.map((div) => ({
          value: div?._id,
          label: div?.subCategoryName
        }))
        setSubCategoryOptions(data);
      }
    }
    setSubCategorySport({ value: "", label: "" })
  }, [mainCategorySport]);

  const handleClubs = (selectedOption) => {
    console.log("Selected options", selectedOption)
    console.log("Selected Values", selectedOption?.map((item) => item?.value))
    setClub(selectedOption)
  }

  const selectTypeOptions = [
    { value: "team", label: "Team" },
    { value: "individual", label: "Individual" },
  ]

  const selectFormatOptions = [
    { value: 'knockout', label: "Knockout" },
    { value: 'double_elimination_bracket', label: "Double-Elimination Bracket" },
    { value: 'round_robbin', label: "Round Robbin" }
  ]

  const fixingTypeOptions = [
    { value: 'random', label: "Random" },
    { value: 'manual', label: "Manual" },
    { value: 'sequential', label: "Sequential" },
    { value: 'top_vs_bottom', label: "Top vs bottom" }
  ]

  const selectSportOptions = sportsData?.map((item) => ({
    value: item?._id,
    label: item?.name,
  }))

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F6F7FB",
      cursor: userRole?.role?.staff?.type === true ? state.isDisabled ? 'not-allowed' : 'default' : "",
    }),
  };

  return (
    <div className='h-screen p-4'>
      <form onSubmit={handleCreateTournament}>
        <div className='flex justify-between py-[1.5rem] bg-white mb-5 mt-3 px-3 rounded-md'>
          <div className='flex items-center gap-1 '>
            <Link to="/tournaments">
              <p className='text-[#808080] text-base font-semibold cursor-pointer'>Tournaments</p>
            </Link>
            <FaGreaterThan className='text-[#808080]' />
            <p className='text-lg font-semibold text-[#808080]'>Add New Tournament</p>
          </div>
          <div className='flex justify-center items-center bg-primary rounded-md text-white cursor-pointer px-4 py-2'>
            {isLoading ?
              <PulseLoader color="white" size={8} />
              :
              <button type='submit'>
                <p>Create Tournament</p>
              </button>
            }
          </div>
        </div>
        <div className='bg-white'>
          {loader ? <div className="min-h-[50vh] flex justify-center items-center">
            <RotatingLines
              visible={true}
              height="100"
              width="100"
              color="gray"
              strokeColor="#FB6108"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
            :
            <div className='flex gap-[3rem] items-baseline mb-4 px-[2rem] py-[3rem] bg-white'>
              <div className='flex flex-col gap-[1.5rem] flex-[2]'>
                <div>
                  <Select
                    placeholder='Select Club'
                    isMulti
                    customStyles={customStyles}
                    className={`${userRole?.role?.staff?.type === true ? "cursor-not-allowed" : ""}`}
                    value={club}
                    options={clubOptions}
                    isClearable={true}
                    onChange={handleClubs}
                    isDisabled={userRole?.role?.staff?.type === true}
                  >
                  </Select>
                  {club?.length === 0 && showError && (
                    <p className='text-sm text-red-500'>*This field is required</p>
                  )}
                </div>
                {/*Sub Category Drop Down*/}
                <div>
                  <Select
                    placeholder="Select Sub Category"
                    value={subCategorySport?.value ? subCategorySport : ""}
                    options={subCategoryOptions}
                    isClearable={true}
                    onChange={(selectedOption) => setSubCategorySport(selectedOption)}
                  />
                  {
                    !subCategorySport?.value && showError && (
                      <p className='text-sm text-red-500'>*This field is required</p>
                    )}
                </div>
                {/*Select Format DropDown*/}
                <div>
                  <Select
                    placeholder='Select Format'
                    customStyles={customStyles}
                    value={selectFormat?.value ? selectFormat : ""}
                    options={selectFormatOptions}
                    isClearable={true}
                    onChange={(selectedOption) => setSelectFormat(selectedOption)}
                  >
                  </Select>
                  {
                    !selectFormat?.value && showError && (
                      <p className='text-sm text-red-500'>*This field is required</p>
                    )}
                </div>
                {/*Fixing Type DropDown*/}
                <div>
                  <Select
                    placeholder='Select Fixing Type'
                    customStyles={customStyles}
                    options={fixingTypeOptions}
                    value={fixingType?.value ? fixingType : ""}
                    isClearable={true}
                    onChange={(selectedOption) => setFixingType(selectedOption)}
                  >
                  </Select>
                  {
                    !fixingType?.value && showError && (
                      <p className='text-sm text-red-500'>*This field is required</p>
                    )}
                </div>
              </div>
              <div className='flex flex-col gap-[1.5rem] flex-[2]'>
                {/*Main Category DropDown*/}
                <div>
                  <Select
                    placeholder='Select Main Category'
                    customStyles={customStyles}
                    value={mainCategorySport?.value ? mainCategorySport : ""}
                    options={mainCategoryOptions}
                    isClearable={true}
                    onChange={(selectedOption) => setMainCategorySport(selectedOption)}
                  >
                  </Select>
                  {
                    !mainCategorySport?.value && showError && (
                      <p className='text-sm text-red-500'>*This field is required</p>
                    )}
                </div>
                {/*Select Type DropDown*/}
                <div>
                  <Select
                    placeholder="Select Type"
                    customStyles={customStyles}
                    value={selectType?.value ? selectType : ""}
                    options={selectTypeOptions}
                    isClearable={true}
                    onChange={(selectedOption) => setSelectType(selectedOption)}
                  >
                  </Select>
                  {
                    !selectType?.value && showError && (
                      <p className='text-sm text-red-500'>*This field is required</p>
                    )}
                </div>
                <div>
                  <input
                    type="number"
                    className="w-full text-base font-[500] py-[0.6rem] px-[1rem] bg-gray-50 placeholder:text-[#7F7F7F] rounded-md outline-none"
                    placeholder={`${selectType?.value === "team" ? "Enter No.of Teams" : "Enter No.of Players"}`}
                    value={participants}
                    onChange={(e) => setPartcipants(e.target.value)}
                  />
                  {
                    !participants && showError && (
                      <p className='text-sm text-red-500'>*This field is required</p>
                    )}
                </div>
                {/*Select Sport */}
                <div>
                  <Select
                    placeholder='Select Sport'
                    className='w-full'
                    customStyles={customStyles}
                    options={selectSportOptions}
                    value={selectSport?.value ? selectSport : ""}
                    isClearable={true}
                    onChange={(selectedOption) => setSelectSport(selectedOption)}
                  >
                  </Select>
                  {
                    !selectSport?.value && showError && (
                      <p className='text-sm text-red-500'>*This field is required</p>
                    )}
                </div>
              </div>
            </div>
          }
        </div>
      </form>
      <ToastContainer />
    </div>
  )
}
