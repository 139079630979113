import React from 'react'
import Navbar from './Navbar'
import { Outlet } from 'react-router-dom'

export default function ClubOwnerLayout() {
  return (
    <div className='flex bg-[#E6E6E6]'>
      <div className="flex flex-col flex-1 min-w-0">
        <Navbar />
        <div className="flex-grow w-full overflow-auto px-[1.5rem]">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
